.body {
    margin: 5px 24px;
    height: 26px;
}

.body > div {
    display: flex;
}

.icon {
    width: 22px !important;
    height: 22px;
    margin-right: 8px;
}

.title {
    font-weight: 500;
    font-size: 14px;
    text-align: start;
    line-height: 22px;
}

.dataBody {
    display: inline-flex;
    align-items: center;
}

.data {
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 2px;
}

.unit {
    font-weight: 500;
    font-size: 14px;
    padding-left: 4px;
}
