.wrapper {
    display: flex;
    height: 100%;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.23);
}

.body {
    min-height: 150px;
    padding: 20px;
    width: 100%;
    background-color: white;
    word-break: keep-all;
}

.mobileBody {
    min-height: 130px !important;
}
.mobileBody > div:last-of-type {
    width: 100%;
}

.body p {
    color: #1b2b41b0;
    font-size: 14px;
    margin: 0;
    line-height: 130%;
}

.body span:not(:has(svg)) {
    color: #1b2b41b0;
    font-size: 14px;
    margin: 0;
}

.body .blueText {
    color: #005fdb;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}

.body .boldText {
    color: #192739f0;
    font-weight: 700;
    font-size: 14px;
}

.body .dateText {
    color: #1B2B41B0;
    font-size: 14px;
}

.body .couponTitle {
    color: #192434;
    font-weight: 700;
    margin-bottom: 0;
}

.body .careText {
    color: #e21d12;
    font-size: 14px;
}

.status {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    border-radius: 8px;
    padding: 10px;
}
@media (max-width: 600px) {
    .status {
        width: 90px;
        height: 90px;
    }
}

.status span {
    font-weight: bold;
    color: white !important;
}

.usedStatus {
    background-color: #1C345442;
}

.usableStatus {
    background-color: #005fdb;
}

.infoIcon {
    position: absolute;
    top: 23.5px;
    right: 30px;
    color: #192434;
    font-size: 16px;
}

.couponCode {
    color: #1b2b41b0
}
.couponCode span {
    display: inline-block;
    margin-left: 6px !important;
    padding: 1px 6px 2px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.8px;
    border-radius: 50px;
    border: 1px solid #006af5;
    color: #192739f0 !important;
}
.browser .couponCode {
    position: absolute;
    top: 20px;
    right: 30px;
    letter-spacing: 0;
}
.browser .couponCode span {
    margin-left: 8px !important;
    padding: 1px 8px 2px;
    letter-spacing: 0;
}