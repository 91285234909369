.smartkeyContainer {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    min-height: 240px;
    position: relative;
}

.spotlightBackground {
    background: #2b2b2b;
    top: 0;
    min-height: 240px;
    height: inherit;
    width: 100%;
}

@media (min-width: 460px) {
    .smartkeyContainer {
        min-height: 320px;
    }
}

.btnWrapper {
    z-index: 1;
    display: flex;
    width: calc(100% - 24px);
    margin: 12px;
    bottom: 0px;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
}

.btnWrapper .lockBtn {
    font-size: 14px;
    font-weight: 700;
    display: grid;
    place-items: center;
    width: calc(50%);
    max-height: 64px;
    background-color: #fff;
    border: 0;
    border-right: 1px solid #e6e6e6;
    padding: 8px 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    color: #000000 !important;
}
.btnWrapper .lockBtn:last-child {
    border-right: 0;
}

.btnIcon {
    height: 50px;
}

.btnText {
    font-size: 14px;
    font-weight: 700;
    color: var(--brand-color);
}
.bukgiCar {
    max-width: 100%;
    width: 70%;
    height: auto;
    pointer-events: none;
}
.yaxingCar {
    max-width: 100%;
    width: 60%;
    height: auto;
    pointer-events: none;
}
.yaxingCarHighlight {
    max-width: 100%;
    width: 60%;
    height: auto;
    pointer-events: none;
    animation: brightness 1s ease-in-out;
}

@keyframes brightness {
    0%,
    100% {
        -webkit-filter: invert(none) sepia(none) saturate(none) hue-rotate(none) brightness(none) contrast(none);
    }
    50% {
        -webkit-filter: invert(20%) sepia(91%) saturate(200%);
    }
}

.spotlight {
    max-width: 100%;
    height: auto;
}
.registerBtn {
    display: grid;
    place-items: center;
    width: 100%;
    border: 0;
    background-color: #fff;
    height: 64px;
    border-radius: 10px;
    -webkit-tap-highlight-color: transparent;
    z-index: 12;
}
.registerBtn .btnText {
    font-size: 18px;
}
.noSmartkey.btnText {
    font-size: 16px;
}
