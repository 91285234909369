.body {
    margin-bottom: 20px;
    z-index: 10;
}

.select {
    width: 100% !important;
    height: 100%;
    border-radius: 4px !important;
    border-color: rgba(26, 56, 96, 0.1);
    display: flex !important;
    align-items: center;
}

.select:hover {
    border-color: #006af5;
}

.selectDate div:first-of-type {
    margin-left: 18px !important;
}

.selectDate div:nth-of-type(4) {
    margin-left: 28px !important;
}

.selectDate div:has(svg) span {
    bottom: 3px;
}

.selectDate > span:first-of-type {
    position: absolute;
    left: 10px;
    top: 13px;
    margin-left: 0;
}

.selectDropdown > div > div:last-of-type > div:first-of-type > div > div > div:last-of-type {
    display: none;
}

.selectDropdown > div {
    min-width: 0px !important;
}

.selectDropdown > div > div:last-of-type > div > div > div > div:first-of-type {
    width: calc(100vw - 48px) !important;
}

.selectDropdown table {
    margin: 0 auto !important;
    /* display: block !important; */
    width: 100% !important;
}

@media (max-width: 300px) {
    .selectDropdown table thead,
    .selectDropdown table tbody {
        display: inline-block;
    }
    .selectDropdown table thead th,
    .selectDropdown table tbody td {
        min-width: 30px;
    }
}

.selectDropdown {
    position: relative;
    left: 24px !important;
}

.browserSelectDropdown {
    position: fixed !important;
}

.tag {
    border: none !important;
    background-color: transparent !important;
    line-height: 35px !important;
    font-size: 14px !important;
    font-weight: normal !important;
    position: absolute;
}

.select > div:first-of-type > div > div:has(.tag):not(:first-of-type) {
    display: none;
}

.reservationForm {
    :global .ant-select-item-option-active:not(.ant-select-item-option-selected):not(.ant-select-item-option-disabled):not(:hover) {
        background-color: white !important;
    }
}
