.swiperContainer {
    background: white;
    width: 70%;
    height: 20%;
    min-height: 250px;
    position: fixed;
    bottom: 10px;
    transform: translate(25%, 0);
    z-index: 10;
}

.swiperBtn {
    border: none !important;
    height: 46px !important;
    position: fixed;
    z-index: 6;
}

.swiperBtn:disabled {
    color: lightgray !important;
}
