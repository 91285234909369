.mainPowerTooltip {
    top: 50px !important;
}

.mainPowerTooltip > div:last-of-type > div:last-child {
    width: 100%;
    font-size: 14px !important;
    font-weight: 400;
    color: black;
    background-color: white;
    padding: 20px 24px;
    text-align: center;
    border-radius: 5px;
}

.mainPowerTooltip > div:first-of-type {
    margin-right: 3px;
}

.subRightButton {
    position: absolute;
    right: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: transparent;
    padding: 0;
}
