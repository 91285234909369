.swiper {
    text-align: center;
    height: 100%;
}

.popup {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    text-align: center;
    margin: 0 !important;
    display: block;
    padding: 0 20px !important;
    max-width: 100vw !important;
    top: 0 !important;
}

.popupButton {
    position: relative;
    top: 80%;
    border-radius: 20px !important;
    color: white !important;
    background-color: #394042 !important;
    font-weight: normal !important;
    border: none !important;
}

.leftButtonPopup {
    background-repeat: no-repeat;
    background-size: 100% 464px;
    max-height: 464px;
    height: 105vw;
    width: 100% !important;
    text-align: center;
    display: block;
    padding-bottom: 40px;
}
.leftButton {
    position: relative;
    top: 87%;
    right: 24%;
    border-radius: 20px !important;
    color: white !important;
    background-color: #1e1e1e !important;
    font-weight: normal !important;
    border: none !important;
}
.leftButton:hover {
    filter: brightness(1.4);
}
.centerButton {
    position: relative;
    top: 87%;
    border-radius: 20px !important;
    color: white !important;
    background-color: #1e1e1e !important;
    font-weight: normal !important;
    border: none !important;
}
.centerButton:hover {
    filter: brightness(1.4);
}

.oneWeekPopupBody {
    background-image: url(../../assets/images/home/rent/one_week_popup_background.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    max-height: 464px;
    height: 105vw;
    width: 100% !important;
    text-align: center;
    display: block;
    cursor: pointer;
}
.droneShowPopupBody {
    background-image: url(../../assets/images/home/droneShow_popup_background.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    max-height: 464px;
    height: 105vw;
    width: 100% !important;
    text-align: center;
    display: block;
    cursor: pointer;
}
.fourNightFiveDayPopupBody {
    background-image: url(../../assets/images/home/rent/fourNightFiveDay_popup_background.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    max-height: 464px;
    height: 105vw;
    width: 100% !important;
    text-align: center;
    display: block;
    cursor: pointer;
}

.rentNoticePopupButton {
    opacity: 0;
    display: none;
}

.jejuEventPopupButton {
    position: relative;
    top: 87%;
    border-radius: 20px !important;
    color: white !important;
    background-color: #092852 !important;
    font-weight: normal !important;
    border: none !important;
}
.jejuEventPopupButton:hover {
    color: white !important;
    background-color: #0e3a79 !important;
}

.xMasPopupButton {
    position: relative;
    top: 87%;
    border-radius: 0px !important;
    color: #fcf6ea !important;
    background-color: #002218 !important;
    font-weight: normal !important;
    border: none !important;
}
.xMasPopupButton:hover {
    color: white !important;
    background-color: #032a1e !important;
}

.popupCloseAlldayButton {
    padding: 4px 15px !important;
}

.popupCloseAlldayButton,
.popupCloseButton {
    color: white !important;
}

.popupCloseAlldayButton:hover,
.popupCloseButton:hover {
    color: #ffcc00 !important;
}

.popup div:first-child,
.popup div:last-child {
    padding: 0;
    border: none;
}

.popup > div > div:last-of-type {
    background-color: #1e1e1e;
}

.popup button {
    font-size: 14px !important;
}
@media (max-width: 400px) {
    .leftButton,
    .centerButton,
    .jejuEventPopupButton {
        top: 85% !important;
    }
    .campsitePopupBody .centerButton {
        margin-top: 10px;
    }
    .popupButton {
        top: 78% !important;
    }
}
@media (max-width: 427px) {
    .leftButtonPopup {
        background-size: 100% !important;
        background-position: center;
    }
    .leftButton,
    .centerButton,
    .jejuEventPopupButton {
        width: 70%;
        height: 32px !important;
        line-height: 15px !important;
        top: 90%;
        right: auto;
    }
    .rentNoticePopupBody > .leftButton {
        top: 85% !important;
    }
    .rentNoticePopupBody {
        background-size: 100% !important;
        background-position: center;
    }
    .campsitePopupBody {
        background-size: 100% !important;
        background-position: center;
    }
    .popupButton {
        width: 70%;
        height: 32px !important;
        line-height: 15px !important;
        top: 80%;
    }
}

.imageSliderButton {
    width: 100%;
    height: 100% !important;
    min-width: 200px;
    padding: 8px 30px !important;
    font-weight: bold !important;
    position: relative;
    top: 87%;
    align-items: center;
    border-radius: 20px !important;
}

.linkPopupButton {
    background-color: #1e1e1e !important;
    border: none !important;
    border-radius: 20px !important;
    color: #fff !important;
    display: flex;
    align-items: center;
}

.noEntryPopupButton {
    background-color: #092852 !important;
    border: none !important;
    color: #fff !important;
}
.oneWeekPopupButton {
    background-color: #ffffff !important;
    border: none !important;
    color: #4c1714 !important;
}
.localPromotionPopupButton {
    background-color: #ffffff !important;
    border: 1.5px solid black !important;
    color: black !important;
}
