.body {
}

.body p {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
    text-align: start;
}

.body img {
    border-radius: 2px;
}

.body Col {
    margin-bottom: 10px;
}

.body .title {
    font-weight: 700;
    font-size: 20px;
}

.body .subTitle {
    width: 100%;
    background-color: #1a38601a;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    padding: 10px 0;
    margin-bottom: 4px;
}

.body .content {
    height: 25%;
    font-size: 12px;
    text-align: center;
    word-break: keep-all;
    white-space: pre-line !important;
    padding: 0 5px;
}

.body .hr {
    border-top: 1px solid #001236 !important;
    text-align: center;
}

.body .hr::after {
    content: "·";
    display: inline-block;
    position: relative;
    top: -41px;
    padding: 0 10px;
    color: #001236;
    font-size: 50px;
}
