.modal::-webkit-scrollbar {
    width: 7px !important;
}

.modal::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #979797 !important;
    border-radius: 4px !important;
    width: 7px !important;
}
