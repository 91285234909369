/*OptionCar*/
.option .btn {
    position: absolute;
    z-index: 1;
}

.option .imageDescription {
    width: calc(100% - 20px);
    position: absolute;
    bottom: 0;
    background-color: #000000b2;
    color: #ffffff;
    margin-right: 10px;
    padding: 10px;
}

@media (min-width: 997px) {
    .option .image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 997px) {
    .option .image {
        max-height: 400px;
    }
}

@media (max-width: 992px) {
    .option .btn svg {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 576px) {
    .option .btn svg {
        width: 20px;
        height: 20px;
    }
}

.option .image {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
}

.option .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3.6em;
    line-height: 1.2em;
}
