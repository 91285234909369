.browser {
    .title {
        font-size: 22px;
    }
    .regionName {
        font-size: 16px !important;
    }
    .description {
        font-size: 16px;
    }
    .price {
        font-size: 14px;
    }
    .priceDiscounted {
        font-size: 18px;
    }
    .discountRate {
        font-size: 14px;
    }
    .tag {
        font-size: 12px;
    }
}

.mobile {
    .regionName {
        font-size: 13px;
    }
    .title {
        font-size: 16px;
    }
    .description {
        font-size: 14px;
    }
    .priceDiscounted {
        font-size: 16px;
    }
    .price {
        font-size: 12px;
    }
}

.body p {
    padding: 0;
    margin: 0;
}

.titleImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.regionName {
    color: #1b2b41b0;
}

.title {
    color: #192434;
    font-weight: 700;
}

.description {
    color: #1b2b41b0;
    text-align: left;
}

.price {
    color: #1c304a85;
    text-decoration: line-through;
    font-size: 12px;
    margin-right: 5px !important;
}

.priceDiscounted {
    font-size: 16px;
    font-weight: 700;
    margin-right: 5px !important;
}

.discountRate {
    color: #ff5e00;
    font-size: 12px;
    font-weight: 700;
}

.tagBody {
    text-align: left;
}

.tagBody .tag {
    color: #1b2b41b0;
    border-radius: 4px;
    background-color: #1a38601a;
    border: none;
    margin-bottom: 5px;
    margin-right: 5px;
}

.imageWrapper > div {
    height: 100%;
    min-height: 136.5px;
}
.badge {
    width: fit-content;
    height: fit-content !important;
    background-color: #000000 !important;
    padding: 0 8px 2px 8px !important;
    top: 0px !important;
    left: 0px;
    border-radius: 4px 2px 2px 2px;
}

.topText {
    color: #ffcc00;
    font-size: 16px;
    font-weight: 700;
    margin-top: 6px;
}

.middleText {
    color: #f5f9ff;
    font-size: 12px;
    font-weight: 700;
}
