.digitalKeyPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    min-height: calc(100vh - 75px);
    background: #f0f0f0;
    overflow-x: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.container {
    padding: 24px 16px 16px;
    width: 100%;
}
.topSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.pageTitle {
    display: flex;
    align-items: center;
    width: 100%;
}
.refreshIconContainer {
    display: flex;
    align-items: center;
    justify-content: end;
}
.titleText {
    margin: 0;
    color: var(--brand-color);
    font-size: 18px !important;
    font-weight: bold !important;
}
.titleText.cs {
    font-size: 18px;
}
.dash {
    margin-left: 10px;
    height: 10px;
}
.motionBtn {
    padding: 0.75rem;

    background-color: #fdfdfd;

    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 0;

    -webkit-tap-highlight-color: transparent;
}

.link {
    text-decoration: none;
    color: var(--brand-color);
    font-size: 18px;
    font-weight: bold;

    display: flex;
    align-items: center;
}
.dataContainer {
    margin: 16px 0;

    display: flex;
    width: 100%;

    justify-content: space-between;
    align-items: center;
}
.dataContainer .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
    height: 72px;
    padding: 0px;
    width: 49%;
}

.dataContainer .item:last-child {
    border-right: 0;
}

.itemTitle {
    font-weight: medium;
    font-size: 14px;
}

.littleLowBattery {
    color: #1688f8;
}

.lowBattery {
    color: #ff9500;
}

.veryLowBattery {
    color: #ff0000;
}

.itemData {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
}

.dataDivider {
    height: 48px;
    border-right: 1px solid #ccc;
}

.iconWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    text-decoration: none;
    color: var(--brand-color);
}

.iconWrapper:last-child {
    margin-right: 0;
}

.csIcon {
    max-width: 40px;
    align-self: center;
}
.csIconText {
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
}

.infoWrapper {
    display: flex;
    justify-content: space-between;

    width: 100%;

    padding: 0 20px;
}

.helpIcon,
.arrow {
    width: 30px;
    -webkit-tap-highlight-color: transparent;
}

.button {
    background-color: transparent !important;
    height: 100%;
    margin: 0px 4px;
    padding: 0;
}

.infotext {
    font-weight: 700;
    font-size: 14px;
    margin: 0px 0px 8px 10px;
}

.driveInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 10px;
    background-color: #ffffff;
    height: 126px;
}

.btnBody {
    width: calc(100% - 36px);
    height: 24px;
    position: absolute;
    bottom: 0;
}

.drawerBtnContainer {
    position: fixed;
    bottom: 8px;
    right: 8px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.2);
    display: grid;
    place-items: center;
}

.drawerBtn {
    margin-bottom: 4px;
}

/* 배터리 디자인 */
.batteryWrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 16px 24px;
}
.title {
    font-weight: 500;
    font-size: 14px;
    text-align: start;
    line-height: 22px;
}
