.pageTitle {
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 100%;
}

.titleText {
    margin: 0;
    color: var(--brand-color);
    font-size: 18px !important;
    font-weight: bold !important;
}

.CSpageTitle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

.csContainer {
    min-height: 100%;
    width: 100vw;
    max-width: 520px;
    background-color: white;
    margin: 0 auto;
}

.csInnerContainer {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    margin: 0 auto;
}

@media (max-width: 300px) {
    .csInnerContainer {
        padding: 0 16px;
    }
}

.csIconList {
    display: flex;
    justify-content: space-between;
    min-width: 200px;
    align-items: center;
    margin-top: 12px;
    padding: 0;
}

.iconWrapper {
    max-width: 92px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color: var(--brand-color);
}
.iconWrapper:last-child {
    margin-right: 0;
}
.csIcon {
    max-width: 40px;
    align-self: center;
}
.csIconText {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
}

.dash {
    margin-left: 10px;
    height: 10px;
}

.motionBtn {
    padding: 0.75rem;
    background-color: #fdfdfd;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 0;
    -webkit-tap-highlight-color: transparent;
}

.button {
    background-color: transparent !important;
    max-width: 92px;
    height: 100%;
    margin: 0;
    padding: 0 !important;
}

.link {
    text-decoration: none;
    color: var(--brand-color);
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.popoverButton {
    text-decoration: none;
    color: var(--brand-color);
    font-size: 18px;
    font-weight: bold;
    align-items: center;
}

.drawer {
    background-color: transparent;
    font-family: "Noto Sans KR", sans-serif !important;
}

.drawer > div:first-of-type {
    max-height: 176px;
    min-height: 30% !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -4px 12px !important;
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
}

.drawerBtn {
    background-color: transparent !important;
    width: 100%;
    height: 10px;
}

.drawerBtn:active {
    background-color: transparent !important;
}

.react-modal-sheet-header {
    display: none !important;
}

.popover > div > div:last-of-type > div {
    padding: 16px;
}

.wrapper .motionBtn {
    height: 48px;
    margin-right: 1rem;
    background-color: #fdfdfd;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 0;
}

.wrapper .motionBtn:last-child {
    margin-right: 0;
    margin-bottom: 0;
}
.wrapper .link {
    text-decoration: none;
    color: var(--brand-color);
    font-size: 16px;
    font-weight: bold;

    display: flex;
    align-items: center;
}

.wrapper .pageTitle {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 16px;

    width: 100%;
}
.wrapper .titleText {
    margin: 0;
    color: var(--brand-color);
    font-size: 16px !important;
    font-weight: bold !important;
}
