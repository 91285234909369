.btn {
    height: fit-content !important;
    min-height: 0px !important;
    border-radius: 4px !important;
}

.darkBtn {
    background: #001236 !important;
    border-radius: 4px !important;
    border: 1px solid rgba(26, 56, 96, 0.1) !important;
}

.borderLightBtn {
    background: white;
    border-radius: 4px !important;
    border: 1px solid #001236 !important;
}
