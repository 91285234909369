.body {
    width: 100%;
    max-width: 500px;
    min-width: 320px;
    height: 100%;
    word-break: keep-all;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -o-text-size-adjust: none;
}
.wrapper {
    width: 100%;
    height: 100%;
}
.wrapper > div:last-of-type {
    height: 100%;
}

.title h5 {
    width: fit-content;
    color: #e94833;
    font-size: 20px !important;
    margin: 0 0 4px 0;
}
.title h4 {
    margin: 0 0 12px 0;
}
.title p,
.body p,
.resultWrapper p {
    font-size: 16px !important;
    margin: 0;
    line-height: 20px;
}

.body .input {
    background-color: white;
    padding: 14px 16px;
    border-radius: 4px;
    font-weight: 700;
    height: 50px;
    border: 1px solid rgba(28, 52, 84, 0.5);
    font-size: 16px !important;
}
.body .input:focus {
    border: 2px solid #006af5;
    box-shadow: 0px 0px 4px 0px #006af5;
}

.checkbox input + span {
    width: 20px;
    height: 20px;
    border-color: #006af5;
}
.checkbox input + span::after {
    width: 6px;
    height: 10px;
}
.body .agreeBody {
    border: 1px solid rgba(28, 52, 84, 0.26);
    border-radius: 4px;
    padding: 10px 20px;
    color: #1b2b41b0;
    background-color: #fff;
}
.body .agreeBody span {
    margin: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.body .textDanger {
    color: var(--danger);
    margin: 0;
    font-size: 15px !important;
    font-weight: normal !important;
}

.btnWrapper {
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: space-evenly;
}
.body .btn {
    width: 100%;
    font-weight: 700;
    height: 50px !important;
    border-radius: 4px;
}
.primaryBtn {
    background-color: #e94833 !important;
}
.secondaryBtn {
    background-color: white;
    color: #e94833 !important;
    border-color: #e94833 !important;
}
.secondaryBtn:hover,
.secondaryBtn:focus {
    background-color: white;
    color: #e94833 !important;
}
.primaryBtn:hover,
.primaryBtn:focus {
    color: white !important;
    background-color: #e94833 !important;
}
.btn:disabled {
    background-color: #e9e9e9 !important;
}

.resultWrapper {
    text-align: center;
}

.loading {
    width: 100%;
    height: 100%;
    min-height: 130px;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loading i {
    background-color: #e94833;
}
.loading .text {
    top: 0px;
    position: relative;
    animation: bounce 0.3s ease infinite alternate;
    color: #e94833;
    font-weight: 600;
    font-size: 16px;
}
.loading .text:nth-child(2) {
    animation-delay: 0.1s;
}
.loading .text:nth-child(3) {
    animation-delay: 0.2s;
}
.loading .text:nth-child(4) {
    animation-delay: 0.3s;
}
.loading .text:nth-child(5) {
    animation-delay: 0.4s;
}
.loading .text:nth-child(6) {
    animation-delay: 0.5s;
}
.loading .text:nth-child(7) {
    animation-delay: 0.6s;
}
@keyframes bounce {
    100% {
        top: -4px;
    }
}
