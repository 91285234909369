.confirmModal {
    width: calc(100% - 32px) !important;
}
.confirmModal > div:nth-child(2) {
    border-radius: 4px;
    text-align: center;
}
.modalText {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    margin: 20px 0;
    text-align: center;
    font-family: "Noto Sans KR", sans-serif;
    white-space: pre-line;
    line-height: 24px;
}

.secondaryBtn {
    height: 44px !important;
    min-width: 108px;
    border-radius: 4px !important;
    background-color: white !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.primaryBtn {
    height: 44px !important;
    min-width: 108px;
    border: none !important;
    border-radius: 4px !important;
    background-color: black !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
