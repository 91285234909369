.body hr {
    margin: 10px 0px 10px 0px;
    border: 0.2 solid #1c304a85;
}

.priceBody {
    width: 100%;
}

.priceBody p {
    padding: 0;
    margin: 0;
}

.body .textInfo {
    color: #ca150c;
}

.body .textPrice {
    color: #006af5;
}

.body .textDetail {
    font-size: 12px;
    font-weight: 700;
}

.body .payment p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.body .payment .btnEdit {
    border: none;
    color: #ef3d3d;
    box-shadow: none;
    padding: 0;
}

/*order*/

@media (min-width: 768px) {
    .rightBody {
        width: 80%;
        margin: auto;
    }

    .body .card {
        bottom: 20px !important;
    }
}

.body .card {
    min-width: 283px;
    position: absolute;
    bottom: 0;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    border: none;
    box-shadow: 0 4px 7px 0px rgba(0, 0, 0, 0.2) !important;
    padding: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    background: white;
}
.mobileScreen {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    padding: 1.5rem 1.5rem 1.5rem;
}
.cardMobile {
    min-width: 283px !important;
    text-align: center;
    border: none;
    position: absolute;
    bottom: -40px;
    font-size: 1rem;
    font-weight: 700;
    box-shadow: 0 4px 7px 0px rgba(0, 0, 0, 0.2) !important;
    padding: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    background: white;
}
/*myOrder*/
.body .radioGroup {
    border: 1.4px solid #aeb3bc;
    border-radius: 4px;
}

.underBody {
    margin-top: 3rem;
}

@media (max-width: 300px) {
    .mobileScreen {
        padding: 1rem 1rem 1rem;
    }
}

@media (max-width: 768px) {
    .underBody {
        padding-bottom: 4rem;
    }
}
