.mapBtnCircle {
    position: absolute;
    top: 50px;
    right: 8px;

    z-index: 1;

    width: 42px;
    height: 42px;
    border-radius: 100%;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    user-select: none;
}

.searchOnThisMapBtn {
    top: 108px;
}

.myLocationDot {
    width: 18px;
    height: 18px;
    border: 3px solid white;
    border-radius: 100%;

    background: #447dee;
    box-shadow: 0px 0px 8px #447dee;
}

.myLocationShadow {
    margin-left: -7px;
    margin-top: 0px;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: rgba(68, 181, 238, 0.3);

    animation: 1.75s ease-in-out infinite flickerAnimation;
}

.sheetContentBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-bottom: 16px;
}
.stationName {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}
.stationAddr {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 12px;
}
.pill {
    border-radius: 50px;
    width: 100px;
    height: 28px;

    font-size: 12px;
    color: #000;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}
.companyPill {
    background: #f5f5f5;
}
.lightblue {
    box-shadow: 0 0 0 3px #58d5ff inset;
}
.lightgreen {
    box-shadow: 0 0 0 3px #3fffbf inset;
}
.red {
    box-shadow: 0 0 0 3px #f63b3b inset;
}
.yellow {
    box-shadow: 0 0 0 3px #ffcc00 inset;
}

.navigateBtn {
    height: 56px !important;
    color: white;
    background: #ffcc00;
    font-size: 16px;
    font-weight: 700;
}

.navigateBtn:hover {
    background: #ffcc00;
}
.navigateBtn:active {
    background-color: #505e7a;
}

.sheetTitle {
    text-align: center;
    margin: 8px auto;
    margin-bottom: 24px;

    font-size: 18px;
    font-weight: 500;
}
.naviIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
    color: inherit;
    user-select: none;
}
.naviIcon {
    width: 64px;
    height: 64px;
    border-radius: 20px;

    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.2);
}
.naviAppText {
    font-size: 12px;
    margin-top: 8px;
}

.loadingWrapper {
    z-index: 99;
    position: fixed;
    width: 100%;
    height: calc(100% - 75px);
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Noto Sans KR", sans-serif;
}

.loadingBox {
    margin: 0 auto;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 20px;
    border-radius: 12px;
}

.loadingBox p {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.loadingBox .title {
    font-size: 16px;
    font-weight: 600;
}

.loadingSpin i {
    background-color: #ffcc00 !important;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.legend {
    position: absolute;
    width: fit-content;
    height: auto;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: white;
    margin: 2px;
    padding: 4px 8px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    font-size: 12px;
}
