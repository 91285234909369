.customModal div {
    margin-left: 0px !important;
}

.customModal::-webkit-scrollbar-thumb,
.snap-container::-webkit-scrollbar-thumb,
.orderBox::-webkit-scrollbar-thumb,
.ready::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #979797;
    border-radius: 4px;
}

.paymentButtonWrapper {
    width: 100%;
    height: 100%;
    display: flex !important;
    gap: 16px;
}
.paymentButton {
    width: 100%;
    height: 40px !important;
    text-align: center;
    line-height: 37px !important;
    border-radius: 4px !important;
}

@media (max-width: 400px) {
    .paymentButtonWrapper {
        display: block !important;
    }
    .paymentButton:not(:last-of-type) {
        margin-bottom: 8px !important;
    }
}
