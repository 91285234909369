.mobile .btnBottom,
.btnBottom {
    width: 90% !important;
    position: fixed;
    bottom: 14px;
    font-weight: 700;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 4;
}
.btnBooking {
    width: 90% !important;
    min-height: 40px;
    position: fixed !important;
    bottom: 14px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 3;
    border-radius: 4px !important;
}

.cardWrapper {
    position: sticky !important;
    top: 154px;
    align-self: flex-start;
    :global .ant-select-disabled,
    :global .ant-select-disabled * {
        cursor: default !important;
    }
}
@media (max-height: 750px) {
    .cardWrapper {
        position: sticky !important;
        top: 102px;
        align-self: flex-start;
    }
}
.card {
    border-radius: 16px;
    box-shadow: 0px 1px 4px -1px rgba(28, 48, 74, 0.5);
}

:global .custom-select .ant-select-selector {
    color: rgba(0,0,0,.85) !important;
}