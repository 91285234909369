.activeBody {
    height: 176px !important;
    width: calc(100% - 8px);
    padding: 16px 0px 16px 0px !important;
    border-radius: 12px !important;
    background-color: #ffcc00 !important;
    border: none !important;
}
.inactiveBody {
    height: 176px !important;
    width: calc(100% - 8px);
    padding: 16px 0px 16px 0px !important;
    border-radius: 12px !important;
    background-color: #f0f0f0 !important;
    border: none !important;
}

.inactiveBody:hover {
    color: #000000;
}

.activeBody div {
    display: block;
}

.inactiveBody div {
    display: block;
}

.activeBody span {
    margin: 0;
    position: relative;
    border-radius: 20px;
    padding: 0 8px;
}

.inactiveBody span {
    position: relative;
}

.innerBody {
    width: 100%;
}

.notClick {
    pointer-events: none;
}

p {
    margin: 0px;
}

.emptyText {
    font-weight: 500;
    font-size: 16px;
    padding: 49px 73px 49px 73px;
    color: rgba(6, 26, 66, 0.6);
}

.carImg {
    max-width: 100%;
    height: auto;
    pointer-events: none;
    margin: 0 auto;
}

.carNumberText {
    font-weight: 700;
    font-size: 16px;
    color: var(--brand-color);
    margin: 0;
}

.activeText {
    visibility: visible;
    font-weight: 500;
    font-size: 14px;
    color: white;
    height: 18px;
}

.modalText {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    margin: 20px 0;
    text-align: center;
    font-family: "Noto Sans KR", sans-serif;
    white-space: pre-line;
}

.modalBtn {
    background: var(--brand-color);
    width: 108px;
    height: 44px;
}

.checkbox {
    position: absolute;
    left: 10px;
    top: 10px;
}

.checkbox :disabled {
    visibility: hidden;
}

.checkbox :active {
    visibility: visible;
}

.hideCheck {
    display: none;
}
