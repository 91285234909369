@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
    --vh: 100%;
    color-scheme: light only !important;
}
body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Noto Sans KR", sans-serif !important;
    background-color: #fff;
    overflow-x: hidden !important;
    word-break: keep-all;
}
@media (prefers-color-scheme: dark) {
    html,
    body {
        background-color: white;
        color: black;
    }
}
img {
    -webkit-user-drag: none;
}
body:has(.footer) main {
    min-height: calc(100vh + 1px);
}
body:has(.scrollNone) {
    width: auto !important;
    overflow: hidden !important;
}
body::-webkit-scrollbar,
.snap-container::-webkit-scrollbar,
.orderBox::-webkit-scrollbar,
.ready::-webkit-scrollbar {
    width: 7px;
}
.mobile .custom-achor .ant-anchor::-webkit-scrollbar {
    display: none;
}
.option-list::-webkit-scrollbar {
    height: 4px;
}
.option-list::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #979797;
    border-radius: 4px;
}
body::-webkit-scrollbar-thumb,
.snap-container::-webkit-scrollbar-thumb,
.orderBox::-webkit-scrollbar-thumb,
.ready::-webkit-scrollbar-thumb,
.designScroll::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #979797;
    border-radius: 4px;
}
.designScroll::-webkit-scrollbar {
    height: 8px;
    background-color: rgba(0, 0, 0, 0.1);
}

.ant-layout {
    background-color: transparent !important;
}

input:-webkit-autofill:not(:focus),
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.space-w-100,
.space-w-100 > div:not(:nth-of-type(2)) {
    width: 100%;
}

/* FONT SIZE */
h1 {
    font-size: 3rem !important;
}
h2 {
    font-size: 2.5rem !important;
}
h3 {
    font-size: 2rem !important;
}
h4 {
    font-size: 1.5rem !important;
}
h5 {
    font-size: 1.25rem !important;
}
h6,
.b-lg {
    font-size: 1.125rem !important;
}
.b-md {
    font-size: 1rem !important;
}
.b-sm {
    font-size: 0.875rem !important;
}
.b-xs {
    font-size: 0.75rem !important;
}
@media (max-width: 500px) {
    h3 {
        font-size: 1.5rem !important;
    }
}
@media (max-width: 992px) {
    body {
        font-size: 0.875rem !important;
    }
    h1 {
        font-size: 2.25rem !important;
    }
    h2 {
        font-size: 2.125rem !important;
    }
    h4 {
        font-size: 1.25rem !important;
    }
    h5 {
        font-size: 1.125rem !important;
    }
    h6,
    .b-lg {
        font-size: 1rem !important;
    }
    .b-md {
        font-size: 0.875rem !important;
    }
    .b-sm,
    .b-xs {
        font-size: 0.75rem !important;
    }
}
@media (min-width: 1600px) {
    h1 {
        font-size: 3rem !important;
    }
    h2 {
        font-size: 2.5rem !important;
    }
    h3 {
        font-size: 2rem !important;
    }
    h4 {
        font-size: 1.5rem !important;
    }
    h5 {
        font-size: 1.25rem !important;
    }
    h6,
    .b-lg {
        font-size: 1.125rem !important;
    }
    .b-md {
        font-size: 1rem !important;
    }
    .b-sm {
        font-size: 0.875rem !important;
    }
    .b-xs {
        font-size: 0.75rem !important;
    }
}

/* FONT WEIGHT */
.b-bd,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700 !important;
}
.b-rg {
    font-weight: 400 !important;
}
.b-li {
    font-weight: 300 !important;
}

/* GRID */

/********** Template CSS **********/
.main-body {
    overflow: hidden;
}

:root {
    --primary: #ffcc00;
    --light: #fff;
    --dark: #001236;
    --danger: #e21d12;

    --navbar-height: 75px;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}

@media (max-width: 767px) {
    .back-to-top {
        right: 16px;
        bottom: 16px;
    }
}

/*** Animation ***/
.motion {
    animation: motion 0.6s linear 0s infinite alternate;
}
@keyframes motion {
    0% {
        margin-top: 0px;
    }
    100% {
        margin-bottom: 4px;
    }
}

.fadeIn {
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes fadein {
    /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-o-keyframes fadein {
    /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeInUp {
    animation: fadeInUp 1s;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
}

.fadeInLeft {
    animation: fadeInLeft 1s;
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-20%, 0, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
}

.fadeInRight {
    animation: fadeInRight 1s;
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(20%, 0, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
}

.fadeOut {
    animation: fadeout 5s;
    -moz-animation: fadeout 5s; /* Firefox */
    -webkit-animation: fadeout 5s; /* Safari and Chrome */
    -o-animation: fadeout 5s; /* Opera */
}
@keyframes fadeout {
    0%,
    25% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-moz-keyframes fadeout {
    /* Firefox */
    0%,
    250% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes fadeout {
    /* Safari and Chrome */
    0%,
    25% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-o-keyframes fadeout {
    /* Opera */
    0%,
    25% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity 0.5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}

/*** Button ***/
.btn {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    transition: 0.5s;
}
.btn-lg {
    height: 3.5rem;
    margin: 0 0.5rem;
    padding: 1rem 1.75rem;
    font-weight: 400;
    font-size: 1.25rem;
}
.btn-md {
    height: 3.5rem;
    margin: 0 0.5rem;
    padding: 0.5rem 1.5rem;
    font-weight: 400;
    font-size: 1rem;
}
.btn-sm {
    height: 3.5rem;
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.813rem;
}
.btn.btn-primary {
    background-color: #ffcc00;
    font-weight: bold;
    border: none;
}
.btn.btn-primary:hover {
    background-color: #001236 !important;
}
.btn-dark {
    min-height: 45px;
    background-color: #001236;
    font-weight: bold;
    border: none;
}
.btn-dark:hover {
    background-color: #ffcc00;
}
.btn.btn-primary:hover,
.btn.btn-secondary:hover {
    color: #ffffff !important;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active,
.login .ant-btn-primary[disabled]:hover,
.login .ant-btn-default[disabled]:hover {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: default !important;
    border-color: #d9d9d9 !important;
}
a {
    text-decoration: none !important;
}

/*** Navbar ***/
.navbar-dark .menuLayout {
    align-items: center;
    background: black;
    font-weight: normal;
    text-decoration: none;
}
.navbar-light .menuLayout {
    align-items: center;
    background: transparent;
    font-weight: normal;
    text-decoration: none;
}
.menuLayout li {
    position: relative !important;
    padding: 0 !important;
}
.menuLayout .text-light,
.menuLayout .text-special {
    padding: 0 20px !important;
}
.navbar-light .menuLayout .text-light {
    color: #231815 !important;
}
.navbar-light .ant-menu-item:hover,
.navbar-light .ant-menu-submenu:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 2px;
}
.navbar-light .ant-menu-item:hover .ant-menu-title-content .text-light,
.navbar-light .ant-menu-submenu:hover .ant-menu-title-content .text-light {
    color: #001236 !important;
}
.ant-menu-item:hover .ant-menu-title-content .text-light,
.ant-menu-item:hover .ant-menu-title-content .text-special,
.ant-menu-submenu:hover .ant-menu-title-content .text-light,
.ant-menu-submenu:hover .ant-menu-title-content .text-special {
    color: #ffcc00 !important;
}
.ant-menu-submenu-popup {
    box-shadow: 0 3px 6px 0px rgb(0 0 0 / 12%);
    top: 85px;
}
.ant-menu-vertical > .ant-menu-item-active:hover .nav-link,
.ant-menu-vertical > .ant-menu-item:hover .nav-link,
.ant-menu-submenu-vertical:hover .nav-link {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #000 !important;
    border-radius: 2px;
    text-decoration: none !important;
}
.ant-menu-title-content a {
    text-decoration: none !important;
}
.navbar-light .text-special {
    color: #006af5 !important;
}
.ant-menu-sub .ant-menu-submenu-title,
.ant-menu-sub .ant-menu-submenu-title:hover {
    color: rgba(0, 0, 0, 0.85) !important;
    padding: 0 8px !important;
    margin: 0 0 8px 0 !important;
}
.ant-menu-sub .ant-menu-submenu-title .nav-link {
    padding: 0 10px;
}
.disabledMenu {
    color: #cecece !important;
    cursor: default !important;
}

.ant-menu-title-content a::before,
.ant-menu-title-content::after,
.ant-menu-title-content::before {
    display: none;
}
.ant-menu-horizontal {
    border-bottom: none;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
    display: none;
}
.ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
    border: none !important;
    box-shadow: none;
}
.ant-menu-item,
.ant-menu-item-selected,
.ant-menu-item:hover,
.ant-menu-submenu-title {
    justify-content: center;
    text-align: center;
    margin: 0;
    background-color: transparent !important;
    min-width: max-content;
    text-transform: uppercase;
    font-size: 16px !important;
    padding: 0.7rem 1rem;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical > .ant-menu-item-active,
.ant-menu-vertical > .ant-menu-item:hover {
    padding: 0 8px !important;
    margin: 8px 0 !important;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-item-selected a,
.ant-menu-item-selected a:focus,
.ant-menu-item-selected {
    color: #000;
}
.ant-menu-sub .ant-menu-item:hover .ant-menu-title-content a {
    color: #ffcc00;
    text-decoration: underline;
}
.ant-menu-title-content .anticon svg {
    margin-left: 5px;
    margin-bottom: 10px;
}

.ant-menu-submenu-arrow {
    display: none;
}

.navbar-dark .navbar-nav {
    align-items: center;
    background: #181d38;
    height: 75px;
    padding: 1rem !important;
}

.navbar-dark .nav-link {
    text-transform: uppercase;
    outline: none;
    min-width: max-content;
}
.ant-menu-submenu-popup > .ant-menu {
    background-color: rgba(255, 255, 255, 0.9) !important;
}
@media (min-width: 991.98px) {
    .menuLayout {
        padding: 0 26px;
    }
}

@media (max-width: 991.98px) {
    .menuLayout {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3) !important;
    }
    .navbar-light {
        position: relative !important;
    }
    .navbar-light:has(.show),
    .navbar-light:has(.collapsing) {
        background-color: rgba(255, 255, 255, 0.9) !important;
    }
    .ant-menu-submenu-popup {
        background-color: rgba(255, 255, 255, 0.9) !important;
    }
    .navbar-light .menuLayout {
        background: rgba(255, 255, 255, 0.9);
        position: absolute;
        top: 75px;
        border-top: rgba(237, 237, 237, 0.9) 1px solid;
    }
    .navbar .navbar-nav .nav-link {
        margin-right: 0;
    }

    .navbar .navbar-nav {
        border-top: 1px solid #eeeeee;
        height: 100%;
        position: relative;
        top: 7px;
    }

    .dropdown-menu {
        padding: 0.5em !important;
    }
    .ant-menu-inline {
        border-right: none;
    }
    .ant-menu-inline .ant-menu-submenu {
        padding: 0 16px;
    }
    .ant-menu-inline .ant-menu-submenu-open {
        padding: 0 16px 16px 16px;
    }
    .ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-titl,
    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
        padding: 0 !important;
    }
    .ant-menu-inline .ant-menu-submenu-title,
    .ant-menu-inline > .ant-menu-item,
    .ant-menu-inline > .ant-menu-item-active,
    .ant-menu-inline > .ant-menu-item:hover {
        margin: 5px 0 !important;
    }
    .ant-menu-sub.ant-menu-inline {
        border-radius: 4px;
    }
    .ant-menu-sub.ant-menu-inline a {
        color: #001236;
    }
    .ant-menu-inline .ant-menu-item::after {
        border: none;
    }
    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active {
        color: #ffcc00;
    }
}

.navbar .navbar-brand,
.navbar a.btn {
    height: 60px;
}

.navbar-dark .navbar-nav .nav-link {
    color: var(--dark);
    font-weight: 500;
}

.navbar-dark {
    height: var(--navbar-height);
    width: 100%;
    font-weight: bold;
    background: black;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
    position: fixed !important;
    top: 0px;
    z-index: 999;
}
.navbar-light {
    background: transparent;
    height: var(--navbar-height);
    width: 100%;
    font-weight: bold;
    position: fixed !important;
    top: 0px;
    z-index: 999;
}
.navbar-white .menuLayout .text-light {
    color: #192434 !important;
}
.navbar-white .ant-menu-item:hover .ant-menu-title-content .text-light,
.navbar-white .ant-menu-submenu:hover .ant-menu-title-content .text-light {
    color: #001236 !important;
}
.legal .accordion-button:not(.collapsed) {
    font-weight: 700;
}
.accordion-item {
    border: none !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}

.legal .accordion-button:not(.collapsed) {
    background-color: transparent !important;
    color: #212529 !important;
    box-shadow: none;
}

.accordion-button:focus {
    border: none !important;
    box-shadow: none !important;
}

.nav-item .accordion .accordion-item span {
    white-space: nowrap;
}

.nav-item .dropdown-menu .dropdown-toggle,
.nav-item .dropdown-menu .dropdown {
    background-color: transparent;
    color: #1e2125;
    font-weight: normal;
    text-decoration: none;
    height: 24px;
    padding: 0;
    border: none;
}

.dropdown-menu {
    border: none !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
}

.ant-dropdown-trigger,
.ant-dropdown-menu a {
    text-decoration: none;
}

.dropdown-menu .accordion-button:focus,
.dropdown-menu .accordion-button:focus-visible {
    background-color: #181d38;
    outline: none;
    box-shadow: none;
}

.dropdown-menu .accordion-button:not(.collapsed) {
    color: var(--light);
    background: #181d38;
    box-shadow: none;
}

.dropdown-menu .accordion-button:hover {
    color: var(--primary) !important;
    background-color: #181d38;
}

.dropdown-menu .accordion-button {
    width: 100%;
    justify-content: center;
    background: #181d38;
    padding: 0;
    color: var(--light);
    font-weight: normal;
    border: none;
    outline: none;
    padding: 0.5rem 0;
}

.dropdown-menu .accordion-button div {
    align-items: center;
    justify-content: center;
    height: 26px;
    padding: 0 0.25rem;
}

.dropdown-menu .accordion-button::after {
    display: none;
}

.dropdown-menu .custom-show-dropdown {
    opacity: 1;
    z-index: 5;
    transition: opacity 0.3s, height 0.3s, transform 0.3s;
    height: max-content;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border: none;
    margin-top: 15px;
    transform: translateY(-10%);
}

.support .accordion-collapse {
    background-color: #f0f0f0;
}

.support button {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
}

.support ul {
    margin: 10px 0 !important;
}

.support li,
.support p {
    font-size: 16px !important;
    margin: 0;
}

.accordion-item {
    background: transparent;
    border: 0;
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dropdown-item {
    justify-content: center;
    width: 100%;
    padding: 0.4rem !important;
    border-radius: 4px;
    text-align: center !important;
}

.dropdown-item:hover {
    background: #ffcc00 !important;
    color: var(--dark);
}

.dropdown-item a {
    color: #001236;
    text-decoration: none;
}

.dropdown-item a:hover {
    color: #001236;
}
.navbar-toggler {
    padding: 0 !important;
}
.navbar-toggler:focus {
    box-shadow: none !important;
    border: none !important;
}
.textarea {
    white-space: pre-wrap;
}
.ant-notification,
.ant-modal {
    white-space: pre-wrap;
    z-index: 9999;
}
.ant-modal-confirm-title {
    font-weight: bold !important;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 0;
}
.ant-modal-wrap:has(.homePopup) {
    width: 427px;
    height: fit-content;
    top: 100px;
    overflow: hidden;
}
@media (max-width: 427px) {
    .ant-modal-wrap:has(.homePopup) {
        width: 100vw !important;
        top: 134px;
        z-index: 1;
    }
}
/* 팝업 안 겹치게 하는 부분 */
@media (min-width: 1224px) {
    .ant-modal-wrap:has(.secondPopup) {
        left: 400px;
    }
    .ant-modal-wrap:has(.thirdPopup) {
        left: 800px;
    }
}
.ant-modal {
    width: 500px;
}
@media (min-width: 992px) {
    .dropdown-menu .accordion-collapse {
        position: absolute;
        left: -10%;
    }

    .navbar-nav {
        padding: 0;
    }

    .dropdown-menu .accordion-button:not(.collapsed) {
        border: 0;
        box-shadow: none;
    }

    .dropdown-menu.nav-item {
        margin-right: 24px;
    }

    .dropdown-menu .accordion-button {
        padding: 0;
    }

    .accordion-collapse.custom-show-dropdown {
        margin-top: 16px;
    }

    .dropdown-menu .dropdown-item {
        justify-content: flex-start;
    }
}
.digitalKeyModal,
.confirmModal {
    width: calc(100vw - 32px) !important;
    max-width: 400px;
}
.digitalKeyModal .ant-modal-body,
.confirmModal .ant-modal-body {
    width: 100%;
    padding: 0;
    border: none;
    text-align: center;
}

.confirmModal img {
    display: block;
    margin: 0 auto;
    max-width: 90px;
}

.digitalKeyModal .ant-modal-content,
.confirmModal .ant-modal-content {
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.3);
    padding: 12px 24px;
    text-align: center;
    border-radius: 8px;
}

.confirmModal .ant-modal-body > div {
    font-weight: 500;
    font-size: 16px !important;
    word-break: keep-all;
    text-align: center;
    vertical-align: middle;
    color: #000000;
    margin: 20px 0;
    font-family: "Noto Sans KR", sans-serif;
    white-space: pre-line;
}

.confirmModal .title {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    word-break: keep-all;
}

.confirmModal .ant-modal-body span {
    font-weight: 400;
    font-size: 14px;
}

.confirmModal .ant-modal-footer {
    display: flex;
    justify-content: center;
    margin: 0 20px;
    padding: 0;
}

.confirmModal button {
    height: 44px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
}

.confirmModal .ant-btn-primary {
    background-color: black !important;
    color: white !important;
}

.confirmModal .ant-btn-default.ant-btn-dangerous {
    border-color: var(--danger);
    color: var(--danger);
}

.confirmModal .ant-btn-primary.ant-btn-dangerous {
    background-color: var(--danger);
    color: white;
}

/*
    carousel
*/
.carousel-indicators {
    bottom: -50px !important;
}
.carousel-indicators [data-bs-target] {
    width: 15px !important;
    height: 15px !important;
    margin: 0 5px !important;
    border: 0;
    border-radius: 50px;
    border-top: none !important;
    border-bottom: none !important;
}

.carousel-indicators button {
    background-color: black !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: black;
    opacity: 0.7;
    border-radius: 20px;
    width: 35px !important;
    height: 35px !important;
}
.ant-image-preview-operations {
    bottom: 0;
    top: auto;
}
.ant-image-preview-img {
    max-height: 70% !important;
}

/*** Section Title ***/
.section-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
}

.section-title::before {
    position: absolute;
    content: "";
    width: calc(100% + 80px);
    height: 2px;
    top: 6px;
    left: -40px;
    background: var(--primary);
    z-index: -1;
}

.section-title::after {
    position: absolute;
    content: "";
    width: calc(100% + 120px);
    height: 2px;
    bottom: 6px;
    left: -60px;
    background: var(--primary);
    z-index: -1;
}

.section-title.text-start::before {
    width: calc(100% + 40px);
    left: 0;
    top: 8px;
}

.section-title.text-start::after {
    width: calc(100% + 60px);
    left: 0;
    bottom: 8px;
}

@media (max-width: 992px) {
    .section-title.text-start::before {
        width: 100%;
    }

    .section-title.text-start::after {
        width: 100%;
    }
}

/*** Service ***/
.service-item {
    background: var(--light);
    transition: 0.5s;
}

.service-item:hover {
    margin-top: -10px;
    background: var(--primary);
}

.service-item * {
    transition: 0.5s;
}

.service-item:hover * {
    color: var(--light) !important;
}

/*** Categories & Courses ***/
.category img,
.course-item img {
    transition: 0.5s;
}

.category a:hover img,
.course-item:hover img {
    transform: scale(1.1);
}

/*** Team ***/
.team-item img {
    transition: 0.5s;
}

.team-item:hover img {
    transform: scale(1.1);
}

/*** Footer ***/
.mobile .footer .no-order p,
.mobile .footer .no-order .footer-anchor {
    margin-top: 0;
    font-size: 12px !important;
    color: #999 !important;
}

.mobile .footer .company-name {
    font-size: 13px !important;
    font-weight: 700 !important;
    color: #ffffffcc !important;
}

.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    font-weight: normal;
    border: 1px solid #ffffff;
    border-radius: 35px;
    transition: 0.3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #ffffff;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: 0.3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    margin-top: 8px;
    padding: 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright .footer-anchor {
    color: var(--light);
}
.footer .footer-menu img {
    margin-bottom: 10px;
    cursor: pointer;
}
.footer .footer-menu img:not(:last-of-type) {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}
.sub-footer {
    width: 100%;
    position: relative;
    bottom: 0;
    border-top: 1px solid #acacac;
    padding: 15px 0;
    background-color: white;
}
.sub-footer a,
.sub-footer p {
    margin-bottom: 0;
    color: #000;
    text-decoration: none;
}

.TOS-wrapper a {
    color: #ffcc00 !important;
    text-decoration: none;
}

.footer .order .copyright {
    padding: 0;
    font-size: 15px;
    border-top: 1px solid #1c304a85;
}

.footer .order .TOS-wrapper a {
    color: #006af5 !important;
    text-decoration: none;
}

.footer .order .container {
    padding: 0;
}

.footer .order .ant-col {
    padding: 0 !important;
}

/*** Custom ***/
.bg-footer {
    background-color: #191919;
}

.text-skyblue {
    color: #8dbad2 !important;
}

.text-special {
    color: #ffe16b !important;
}

.source-text {
    display: flex;
    flex-direction: column-reverse;
    text-align: right;
    right: 0px;
    color: #a3a3a3;
    font-size: 13px;
}

.magnifier-btn {
    right: 8px;
    bottom: 0;
    width: 50px;
    height: 50px;
    background-color: transparent;
    opacity: 1;
    border: none;
}

.magnifierIcon {
    color: white !important;
    height: 20px;
    font-size: 20px;
}

.navbar .navbar-toggler {
    color: #fff !important;
    border: none !important;
}

.bg-gray {
    background-color: #191919;
}

.mobile .bg-gray {
    background-color: #333;
}

.listSpace li:not(:last-of-type) {
    margin-bottom: 4px;
}

/* 
    Custom NAVBAR btn
*/
.btn {
    border-radius: 4px;
    padding: 0.75rem 0.5rem;
}

.rent-btn {
    color: var(--dark) !important;

    width: 100%;
    height: 100%;
}

.rent-btn-nav {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 150px;

    height: 48px !important;

    border-radius: 4px;
    margin-top: 4px;
}

.catalog-btn {
    width: 100%;
    height: 100%;

    background: #efefef;
}

.ruta-btn {
    border: 0;
    width: 100%;
}

@media (min-width: 992px) {
    .rent-btn {
        display: none;
    }

    .rent-btn-nav {
        display: flex;
        border-radius: 0;
        margin: 0;
        padding: 0;
        height: var(--navbar-height) !important;
    }

    .catalog-btn {
        margin: 0;
        margin-top: 1.5rem;
    }
}

/* 
    Option page spec table
*/
td {
    word-break: keep-all !important;
}
.optionBox {
    text-align: center;
}
.optionBox .img-fluid {
    width: 80%;
    margin: 50px auto;
}

.design-table .ant-table {
    padding: 0;
}
.design-table {
    margin: 0 auto;
}
.design-table thead {
    background-image: url("assets/images/home/rent/bg_table_header.png");
    background-size: cover;
    border: none;
    color: black;
    border-top: 1px solid #cbd1da;
    border-bottom: 0.5px solid #dfe2e7;
}
.design-table thead th,
.design-table thead td {
    font-weight: bold;
    border-bottom: none;
}
.design-table th,
.design-table td {
    font-weight: normal;
    border-bottom: 0.5px solid #dfe2e7;
    white-space: pre-line;
}
.design-table > :not(:first-child) {
    border-top: none;
}
.design-table th {
    text-align: left;
}
.design-table td {
    text-align: right;
}
.design-table.option th,
.design-table.option td {
    text-align: center;
}
.design-table thead th,
.design-table thead td,
.design-table tbody th,
.design-table tbody td {
    padding: 12px;
}
.design-table tbody th,
.design-table tbody td {
    background-color: white;
}
.design-table .fixedRow,
.pointerRow {
    cursor: pointer;
}
.design-table .fixedRow td {
    background-color: rgb(25, 59, 103, 3%) !important;
}
.design-table td img {
    width: 100%;
}
.design-table td.review-content img {
    width: 50%;
    display: block;
}

.emphasis .design-table th {
    font-weight: 700;
}

.emphasis .design-table td {
    font-weight: 400;
}

.design-table ul {
    padding: 0;
}

@media (max-width: 992px) {
    .optionBox .img-fluid {
        width: 90%;
        margin: 50px auto;
    }
    .design-table {
        width: 100% !important;
        margin: 0 auto;
    }
    .design-table.option tbody td:nth-child(3) {
        text-align: left;
    }
}

.option-image .text-title {
    color: #191919;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.55px;
    padding: 0 10px;
    text-align: left;
}
.option-image .text-list {
    list-style: none;
    padding: 0 10px;
}
.option-image .text-list li {
    text-align: left;
    color: #191919;
    font-size: 14px;
}
.video-wrapper {
    width: 100%;
}

@media (min-width: 992px) {
    .option-image .text-title {
        font-size: 20px;
    }

    .option-image .text-list li {
        font-size: 16px;
    }
}

.hero-btn {
    border: 1px solid inherit;
}

.design__carousel__container {
    max-height: 500px;
}

.copyright p {
    margin: 4px 0;
    font-size: 13px;
    font-weight: 400;
}

.copyright-section {
    font-size: 8px;
}

@media (max-width: 767px) {
    .hero-carousel__md-text {
        font-size: 16px !important;
        font-weight: 500;
    }

    .hero-carousel__sm-text {
        font-size: 14px !important;
    }

    .design__carousel__container {
        max-height: 400px;
    }

    .header-container.model .container {
        max-width: 100vw;
    }
}

@media (max-width: 1024px) {
    .design__carousel__container {
        max-height: 400px;
    }
}

@media (min-width: 576px) {
    .footer .order .container {
        max-width: 100% !important;
    }

    .header-container.model .container {
        max-width: 100vw;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 100% !important;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 100% !important;
    }
}

/* 
    Download Center page
*/
th,
td {
    font-weight: normal;
    padding: 12px 0px;
}

.legal th,
.legal td {
    border: 1px solid #ccc;
}

.th-1,
.th-2 {
    border-right: 1px solid #ccc;
}

@media (max-width: 768px) {
    .legal .th-1 {
        border-right: none;
    }
}

.th-2 .fine-print {
    font-size: 12px;
    font-weight: normal;
}

.footer {
    width: 100%;
}

@media (min-width: 992px) {
    .th-1,
    .th-2 {
        padding: 20px 0;
    }
}

/* 
    Tingle Modal
*/
.info-modal {
    width: fit-content !important;
}

.tingle-modal {
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: default;
}

.tingle-modal-box {
    border-radius: 8px;
}

.tingle-modal-box__footer,
.tingle-modal-box__content {
    display: grid;
    place-items: center;

    background-color: #eee;
    border-radius: inherit;
}

.tingle-modal-box__content {
    padding-bottom: 0;
}

.tingle-btn--primary {
    background-color: #001236;
    border-radius: 4px;
}

.modal-text-sm {
    font-size: 20px;
}

.modal-text-naver {
    font-size: 2.5rem;
}

.modal-border-bottom {
    border-bottom: 4px solid #ffcc00;
}

.modal-border-bottom.green {
    border-bottom: 2px solid #2db400;
}

.naver-store-img {
    width: 80px;
    margin: 1rem 0;
}

@media (max-width: 540px) {
    .tingle-modal {
        display: grid;
        place-items: center;
    }

    .modal-text {
        font-size: 24px;
    }
}

/* 
    개인정보처리방침 + other Legals
*/
.legal .title {
    display: flex;
    align-items: center;
    border-left: 6px solid #ffcc00;
    width: 100%;
    margin: 2rem 0;
    padding-left: 10px;
}

.legal h2 {
    font-size: 18px;
    width: 100%;
    text-align: center;
}

.legal ul {
    padding-left: 0;
    list-style: none;
}

.legal .support ul {
    padding-left: 30px;
    margin-bottom: 0;
    list-style: disc;
}

.legal .support.mobile ul {
    padding-left: 20px;
}

.legal th,
.legal td {
    text-align: center;
    padding: 0.5rem 1rem;
}

.legal h4 {
    font-size: 16px;
}
.legal .accordion-item {
    margin-bottom: 20px;
}
@media (max-width: 540px) {
    .legal {
        font-size: 14px;
        padding: 0;
    }

    .legal h1 {
        font-size: 22px;
    }

    .faq .legal h1 {
        margin-left: 1rem;
    }

    .faq .legal .p-4 {
        padding: 1.5rem 0.75rem !important;
    }

    .accordion-button {
        padding: 0.25rem 0;
    }

    .accordion-body {
        padding: 1rem 0;
    }

    .accordion-body ul {
        margin-bottom: 0;
    }

    .info-img {
        width: 50% !important;
    }
}

/* 
    DESIGN page
*/
.image-gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    margin: 0 auto;
}

@media (min-width: 992px) {
    .image-gallery .single-tile img {
        max-height: 650px !important;
    }
}

.divider {
    width: 100%;
    margin: 1.5rem 0;
    border-bottom: 1px solid white;
}

.divider.black {
    border-bottom: 4px solid #181d38;
    width: max(30%, 200px);
    margin: 6rem auto;
}

.design-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.design-title.interior {
    padding-top: 2rem;
}

.title-dash-before {
    width: 10%;
    margin-right: 1rem;
}

.title-dash-after {
    width: 10%;
    margin-left: 1rem;
}

.dash {
    width: 100%;
    height: 2px;
    background: #181d38;
}

/* slider dots */
.slider-dots {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: 1rem 0;
}

.slider-dot {
    width: 16px;
    height: 16px;
    border-radius: 8px;

    background: rgba(0, 0, 0, 0.4);

    margin-right: 8px;

    cursor: pointer;
}

.slider-dot.dot--active {
    background: rgba(0, 0, 0, 0.9);
}

.fs-title-sm {
    font-size: 1.25rem;
}

.fs-title {
    font-size: 2.75rem;
    letter-spacing: -1.3px;
    font-weight: 700;
}
.fs-title.board {
    font-size: 24px !important;
}

.text-type {
    display: inline-flex;
    text-align: center;
    padding: 0.5rem 2rem;
    border-radius: 20px;
    background: #181d38;
    color: #f0f0f0;
}

.main-title-spacing {
    letter-spacing: -1.6px;
    font-stretch: condensed;
}

/* reactDatePicker */
.react-datepicker__day {
    position: relative;
    width: 12% !important;
    max-width: 2.4rem;
    height: 100%;
    max-height: 2.4rem;
    line-height: 2.4rem !important;
    font-family: "roboto", sans-serif;
    font-size: 16px;
    font-weight: normal !important;
    border-radius: 50px !important;
    border: none;
}
@media (max-width: 300px) {
    .react-datepicker__day {
        height: 100%;
        max-height: 1.9rem;
        line-height: 1.9rem !important;
        font-size: 14px !important;
    }
}
.react-datepicker__day:not(.react-datepicker__day--disabled, .react-datepicker__day--weekend) {
    color: black;
}
.react-datepicker__day:hover {
    background-color: transparent;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start),
.react-datepicker__day--in-range {
    background-color: rgba(0, 18, 54, 0.2) !important;
    color: black !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--keyboard-selected {
    background-color: #001236 !important;
    color: white !important;
}
.react-datepicker__day--weekend:first-of-type:not(.react-datepicker__day--disabled) {
    color: #ff4d4f;
}
.react-datepicker__day--weekend:last-of-type:not(.react-datepicker__day--disabled) {
    color: #006af5;
}

/* LOGIN, SIGNUP */
.login {
    width: 100%;
}
.login .ant-card-body {
    padding-right: 0;
    padding-left: 0;
}
.login .ant-form-item-label > label,
.login .ant-divider-inner-text,
.login .tos-box a {
    color: #565e69;
}
.login .ant-form-item-feedback-icon {
    padding-bottom: 5px;
}
.login .ant-form-item-control {
    display: block;
}
.login .ant-form-item {
    margin-bottom: 1rem;
}
.loginCard {
    width: 600px;
}
@media (max-width: 1000px) {
    .loginCard {
        width: 84%;
        max-width: 600px !important;
    }
}
@media (max-width: 450px) {
    .loginCard {
        margin: 0 24px;
        width: 100%;
        min-width: 270px;
    }
}
.ant-input-disabled,
.ant-input-affix-wrapper-disabled {
    cursor: default !important;
}
.ant-select-selector,
.ant-select-selection-search,
.ant-select-selection-placeholder {
    height: 100%;
    min-height: 45px;
    display: flex;
    align-items: center;
}
.login .login-input {
    display: flex;
    align-items: center;
    padding: 0 8px;
}
.login .login-input,
.login .ant-input-group .ant-input,
.login .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-focused).ant-input-affix-wrapper,
.login
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-focused).ant-input-affix-wrapper:hover {
    height: 45px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}
.login .login-input:not(:disabled, .ant-input-affix-wrapper-disabled),
.login .ant-input-group .ant-input:not(.ant-input-disabled),
.login .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-focused).ant-input-affix-wrapper,
.login
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-focused).ant-input-affix-wrapper:hover {
    background: #ffffff;
}
.login .login-input .ant-input,
.login .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background-color: transparent;
}
.login .login-input .ant-select-selector {
    height: 100%;
}
.login .login-input .ant-select-selector .ant-select-selection-item,
.login .login-input .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
}
.login .ant-input-affix-wrapper-focused,
.login .login-input:focus {
    border: 1px solid #2871e6;
    background-color: #fff;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.login .login-select,
.login .login-select span:not(.ant-select-clear, .anticon-close-circle, .ant-select-arrow) {
    height: 45px !important;
    display: flex;
    align-items: center;
}
.login .login-select > div {
    height: 45px !important;
    background: #ffffff;
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px !important;
}
.login .ant-form-item-control-input-content:has(input:-webkit-autofill:focus) {
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    border-radius: 4px;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.login .ant-input-affix-wrapper-focused .ant-input,
.login .ant-input-group:focus .ant-input {
    background-color: #fff !important;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    border: 1px solid #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:not(:focus),
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover:not(:focus),
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:not(:has(.ant-input:focus)),
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover:not(:has(.ant-input:focus)),
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper .ant-input:not(:focus),
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover .ant-input:not(:focus) {
    background-color: #fde9e8 !important;
}
.ant-input-affix-wrapper-disabled .ant-input-prefix {
    color: #001236;
}
.login .anticon-eye,
.login .ant-space-item {
    cursor: pointer;
}
.ant-modal-confirm .ant-btn-primary {
    box-sizing: border-box;
    background-color: #001236;
    border: #001236 1px solid;
}
.ant-modal-confirm .ant-btn-default:not(.ant-btn-dangerous) {
    box-sizing: border-box;
    border: #bebebe 1px solid;
    color: #001236;
}
.ant-modal-confirm .ant-btn-primary:hover {
    background-color: #ffcc00;
    border: #ffcc00 1px solid;
    color: white;
}
.ant-modal-confirm .ant-btn-default:hover:not(.ant-btn-dangerous) {
    border: #ffcc00 1px solid;
    color: #ffcc00;
}
.ant-modal-confirm .ant-btn-dangerous:hover {
    background-color: #ff6769;
    color: rgb(255, 255, 255);
}
.login .ant-btn-primary {
    height: 45px;
}
.login .ant-btn-primary:not(:disabled) {
    box-sizing: border-box;
    background-color: #001236;
    border: #001236 1px solid;
    border-radius: 4px;
}
.login .ant-btn-default:not(.ant-btn-dangerous) {
    box-sizing: border-box;
    border: #001236 1px solid;
    border-radius: 4px;
    color: #001236;
    height: 45px;
}
.login .ant-btn-dangerous {
    box-sizing: border-box;
    border-radius: 4px;
    height: 45px;
}
.login .ant-col-12 > .ant-btn[disabled] {
    border: none !important;
}
.login .ant-input-group-compact .ant-btn {
    height: 35px;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 5px;
    background-color: white !important;
    border-color: #c2c2c2 !important;
    z-index: 10;
}
.login .ant-btn-primary:hover,
.login .ant-btn-default:hover:not(.ant-btn-dangerous) {
    background-color: #ffcc00 !important;
    border: #ffcc00 1px solid !important;
    color: white !important;
}
.login .ant-btn-dangerous:hover {
    background-color: #ff4d4f !important;
    color: white !important;
}
.login .ant-btn:hover a {
    color: white;
}
.login .tos-box {
    border: 1px solid #e9ecf0;
    padding: 10px;
    margin: 10px 0 1rem 0;
}
.login .tos-box a {
    text-decoration: underline !important;
}
.login .login-group .ant-input-group-compact > .ant-input:first-of-type {
    margin-bottom: 1rem;
}
.capsLock {
    display: none;
}
.ant-input-affix-wrapper-focused + .capsLock {
    display: inline !important;
}

/* MEDIA */
.media-card {
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #dadfe4;
}

.mobile .media-card.review-card {
    height: 100%;
    border-radius: 8px;
}

.media-card:last-child {
    margin-right: 0;
}

.media-card-img {
    width: 100%;
    max-height: 25vh;
    object-fit: cover;
    object-position: 50% 75%;
    margin-bottom: 1rem;
}

.media-button-wrapper {
    display: flex;
    justify-content: center;
}

.media-button {
    margin-top: 1rem;
    cursor: pointer;
}

.media-card .small-text {
    margin: 0;
    font-size: 12px;
    color: gray;
}

.media-card .news-text {
    margin: 0;
    font-size: 14px;
}

.media-card .thumbnail {
    display: flex;
    aspect-ratio: 4/3;
    overflow: hidden;
}

.media-card .thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.media-card .summary {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    color: rgba(27, 43, 65, 0.69);
    font-size: 14px;
}

.media-card .summary .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.media-card .summary .info-item {
    display: flex;
    align-items: center;
    gap: 4px;
}

.media-card .summary .content {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #192434;
    font-size: 14px;
    max-height: 42px;
}

.rent-video-manual .media-card .summary .content {
    max-height: 24px;
    -webkit-line-clamp: 1;
}

@media (min-width: 992px) {
    .media-card-title {
        font-size: 24px;
    }
}

.review-card {
    border: none;
    border-radius: 0;
}
.review-card .thumbnail {
    border-radius: 10px;
}
.review-card .summary {
    padding: 8px 0 0 0;
}

.header-container .container {
    padding-top: 5rem;
}

.header-container.model .container {
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.header-container.model {
    position: relative;
    z-index: 1;
}

.pb-5 {
    padding-bottom: 8rem !important;
}

.btn:focus,
.btn-primary:focus {
    box-shadow: none;
}

.ruta40-banner-img {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
}

.info-img {
    width: 40%;
}

.snap-container {
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow-y: auto;
    scroll-behavior: smooth;
}
.snap-container > div:not(:first-child) {
    scroll-snap-align: end;
    height: 100vh;
}
.legal .accordion-header {
    font-size: 2.5rem !important;
}

.slick-arrow {
    z-index: 1;
    display: grid !important;
    color: rgba(0, 0, 0, 0.3) !important;
    font-size: 40px !important;
    width: fit-content !important;
}
.slick-arrow:hover {
    color: #000 !important;
}
.ant-carousel .slick-prev {
    left: 5%;
}
.ant-carousel .slick-next {
    right: 5%;
}
.ant-carousel .slick-next::before,
.ant-carousel .slick-prev::before {
    content: none;
}
.ant-carousel .slick-dots li button {
    background-color: #000 !important;
    z-index: 1;
}

@media (max-width: 600px) {
    .ant-carousel .slick-dots {
        z-index: 1;
        bottom: 0;
    }
}

.homeImage1 {
    background-image: url("./assets/images/home/campingCar.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}
.homeImage2 {
    background-image: url("./assets/images/home/droneStation.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}
.homeImage3 {
    background-image: url("./assets/images/home/cycleKart.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}
.homeImage4 {
    background-image: url("./assets/images/home/shuttle.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}
.homeImage5 {
    background-image: url("./assets/images/home/refrigerated.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}
.home-title {
    position: absolute;
    top: 17%;
    text-align: center;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.scrollIcon {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.21);
}

@media (max-width: 700px) {
    .swiperButton .btn-primary {
        min-width: 180px !important;
    }
    .swiperButton .btn-secondary {
        min-width: 180px !important;
    }
}
@media (max-width: 349px) {
    .homeImage1,
    .homeImage2,
    .homeImage3,
    .homeImage4,
    .homeImage5 {
        background-size: 330% 100%;
    }
}
@media (max-width: 400px) {
    .swiperButton .btn-primary {
        min-width: 110px !important;
    }
    .swiperButton .btn-secondary {
        min-width: 110px !important;
    }
}
@media (max-width: 992px) {
    .swiperButton {
        bottom: 10% !important;
    }
    .swiperButton > .ant-space-item {
        margin-bottom: 10px !important;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev,
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        top: 50%;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 30px;
    }
}

@media (min-width: 1600px) {
    .home-title h2 {
        font-size: 58px !important;
    }
    .home-title h5 {
        font-size: 30px !important;
    }
    .swiperButton .btn-primary,
    .swiperButton .btn-secondary {
        width: 260px !important;
    }
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev::after,
.swiper-button-next,
.swiper-button-prev::after {
    color: white !important;
    opacity: 0.5;
}
.swiper-pagination-bullet-active {
    background-color: #ffcc00;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    position: relative;
    bottom: 100px;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: 6%;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: 6%;
}
.swiperButton {
    position: absolute;
    left: 0;
    bottom: 10%;
    width: 100%;
    text-align: center;
}
.swiperButton .btn-primary {
    margin-left: 20px;
    padding: 0 !important;
    min-width: 200px;
    height: 45px;
    color: #fff;
    background-color: #001236;
    border: none;
    border-radius: 2px;
    display: inline-block;
}
.swiperButton .btn-secondary {
    padding: 0 !important;
    min-width: 200px;
    height: 45px;
    color: #001236;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    display: inline-block;
}
.swiperButton .btn-primary:hover,
.swiperButton .btn-secondary:hover {
    background-color: #ffcc00;
    border-color: #ffcc00;
    color: #fff;
}
.swiperButton a {
    max-width: 150px;
    color: #001236;
}
.btn-confirm {
    min-width: 200px;
    height: 45px;
    color: #fff;
    background-color: #001236;
    border: none;
    border-radius: 2px;
}
.btn-confirm:hover {
    background-color: #ffcc00;
    border-color: #ffcc00;
    color: #fff;
}
.orderCheckbox {
    min-height: 50px !important;
    width: 100%;
    line-height: 20px !important;
    font-size: 14px;
    color: #1c2e4599;
    border: 2px solid #cbd1da;
    border-radius: 4px;
}
.orderCheckbox > span:last-of-type {
    width: 100%;
    height: 100% !important;
    min-height: 50px;
}
.orderButtons {
    width: 100%;
    font-size: 14px;
}
.orderButtons .ant-radio-button-wrapper,
.orderButtons .ant-checkbox-wrapper,
.orderButtons .ant-checkbox + span,
.orderButtons .ant-select-selector,
.orderButtons .ant-select-selection-item {
    height: 50px !important;
    width: 100%;
    font-size: 14px;
}
.orderButtons.select .ant-radio-button-wrapper,
.orderButtons.select .ant-checkbox-wrapper,
.orderButtons.select .ant-checkbox + span,
.orderButtons.select .ant-select-selector,
.orderButtons.select .ant-select-selection-item,
.orderButtons .ant-select-selection-placeholder {
    height: 50px !important;
    width: 100%;
    line-height: 50px !important;
    font-size: 16px;
}
.orderButtons .ant-radio-button-wrapper {
    color: #1c2e4599;
    border: 2px solid #cbd1da;
    border-radius: 4px;
}
.orderCheckbox:hover,
.orderButtons .ant-radio-button-wrapper:hover {
    color: black !important;
    border: 2px solid #cbd1da;
    border-radius: 4px;
}
.orderButtons .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
}
.orderButtons .ant-radio-button-wrapper-checked {
    border: 2px solid #2871e6 !important;
    border-radius: 4px;
    color: black !important;
    font-weight: 700;
}
.orderButtons .ant-checkbox-group-item:not(:last-of-type),
.orderButtons .ant-radio-button-wrapper:not(:last-of-type) {
    margin-right: 8px;
    margin-bottom: 8px;
}
.orderButtons .ant-checkbox-group-item:not(:last-of-type) span,
.orderButtons .ant-radio-button-wrapper:not(:last-of-type) span {
    min-height: 50px;
}
.orderCheckbox .ant-checkbox-wrapper,
.orderButtons .ant-select-selector,
.orderButtons .ant-checkbox-wrapper {
    color: #1c2e4599;
    border: 2px solid #cbd1da !important;
    border-radius: 4px !important;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
}
.orderCheckbox .ant-checkbox + span,
.orderButtons .ant-checkbox + span {
    padding: 0 15px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.orderButtons .ant-checkbox-wrapper:hover {
    color: black !important;
    border-radius: 4px;
}
/* .orderButtons .ant-checkbox-wrapper:has(.ant-checkbox-checked) {
    border: 1px solid #1890ff;
    color: #1890ff;
} */
.orderCheckbox:has(.ant-checkbox-checked),
.ant-select-focused .ant-select-selector {
    color: black !important;
    font-weight: 700;
    border: 2px solid #2871e6 !important;
    border-radius: 4px;
}
.orderButtons .ant-checkbox-wrapper-checked {
    border: 2px solid #2871e6 !important;
    outline: #2871e6;
    color: black;
    font-weight: 700;
}
.orderButtons .ant-select-selection-item {
    color: black;
    font-weight: 700 !important;
}
.orderCheckbox .ant-row,
.orderButtons .ant-row {
    height: 100%;
    align-items: center;
    min-height: 48px;
}
.orderCheckbox .ant-checkbox-inner,
.orderButtons .ant-checkbox-inner,
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after,
.orderButtons .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}
.orderCheckbox .ant-checkbox-wrapper-disabled span,
.orderButtons .ant-checkbox-wrapper-disabled span {
    color: black;
    font-weight: 700;
    cursor: initial;
}
.orderCheckbox .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled,
.orderButtons .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    border: 2px solid #2871e6 !important;
    border-radius: 4px;
    cursor: initial;
}
.orderImage {
    width: 100%;
    height: calc(100vh - 6rem);
    position: sticky;
    left: 0;
    top: 1.4rem;
}
.orderImage img {
    max-width: 1000px !important;
}

.orderBoxWrap {
    overflow: hidden;
    padding: 0 20px;
}

.orderBox {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 5rem);
    padding-top: 2rem;
}

@media (max-width: 992px) {
    #nest-messages .ant-col-19,
    #nest-messages .ant-col-5 {
        max-width: 100%;
        flex: 100%;
    }
}
.depositInfo input,
.depositInfo .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
}
.depositInfo input,
.depositInfo .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    margin-bottom: 20px;
}
.depositInfo label:after {
    display: none;
}
.depositInfo label {
    height: 50px;
    width: 200px;
    font-weight: bold;
}
.depositInfo input,
.depositInfo input[disabled],
.depositInfo .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: #f5f5f5;
}
.depositInfo .ant-select-single .ant-select-selector .ant-select-selection-item,
.depositInfo .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
}
.depositInfo .ant-select-arrow {
    height: 0;
}

.ant-input[disabled] {
    cursor: default;
    color: #212529;
}
.orderList {
    border-left: none;
    border-right: none;
    border-top: #000 1px solid;
    border-bottom: #000 1px solid;
}
.orderList .ant-list-item {
    border-bottom: #000 1px solid;
    font-size: 16px;
    padding: 10px 0;
}
.orderList .ant-row {
    width: 100%;
}

.notFoundContent {
    width: 100%;
    padding: 1rem;
    text-align: center;
}

.notFoundContent img {
    opacity: 0.85;
}

/* 404 */
.errorResult .ant-result-content {
    background-color: white;
    padding: 0;
}
.errorResult {
    word-break: keep-all;
    height: calc(100vh - 81px - 75px);
    display: table;
    width: 100%;
}
.errorResult .ant-result {
    display: table-cell;
    vertical-align: middle;
}

/*rent*/
.ant-ribbon-corner {
    display: none;
}

.browser .banner .ant-carousel .slick-slider .slick-track,
.browser .banner .ant-carousel .slick-slider .slick-list {
    height: 100%;
}

.box {
    background-color: #193b670d;
    border: none;
    border-radius: 4px;
    padding: 14px;
    margin: 0;
}

.custom-bolder-box {
    background-color: #f4f5f7;
    border: 1px solid #cbd1da;
    border-radius: 10px;
    padding: 14px;
    margin: 0;
}

.rent-secondary-btn {
    height: 45px;
    color: #001236;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #001236;
    border-radius: 4px;
}

.rent-destructive-btn {
    height: 45px;
    color: #e21d12;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #e21d12;
    border-radius: 4px;
}

.line {
    border: 1px solid #1a38601a;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.rent-modal .ant-modal-body {
    padding-bottom: 0;
}

.rent-modal .ant-modal-footer {
    border: none;
    text-align: center;
    padding-bottom: 24px;
}

.list-border-none {
    border: none;
}
.list-border-none .ant-list-item {
    border: none;
    font-size: 16px;
    padding: 0;
}
.list-border-none .ant-row {
    width: 100%;
}

.model-tabs .ant-tabs-nav-list .ant-tabs-ink-bar {
    background: #192434 !important;
}

.model-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    color: #192434;
    width: 100%;
    text-align: center;
}

.model-tabs.tab-sm .ant-tabs-nav-list {
    width: 70%;
}

.model-tabs.tab-lg .ant-tabs-nav-list {
    width: 100% !important;
}

.model-tabs .ant-tabs-tab {
    flex-grow: 1;
    margin: 0 auto !important;
    width: 100%;
}

.model-tabs.title .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    color: white;
}

.model-tabs.title .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
}

.model-tabs.title .ant-tabs-tab {
    font-size: 1.125rem;
    background-color: #ffffff80;
}

.model-tabs.title .ant-tabs-tab-active {
    background-color: white !important;
}

.model-tabs.title {
    position: relative;
    margin-top: -50px;
    z-index: 99;
}

.model-tabs.title .ant-tabs-nav-list .ant-tabs-ink-bar {
    background: none !important;
}

.model-tabs.title .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 15px;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none !important;
    align-self: normal !important;
}

.gallery {
    overflow: hidden;
}

.gallery .image-gallery-bullets {
    bottom: 0;
}

.gallery .image-gallery-bullets .image-gallery-bullet {
    width: 12px;
    height: 12px;
    background-color: white;
    border: 1px solid black;
    box-shadow: none;
}

.gallery .image-gallery-bullets .image-gallery-bullet.active {
    width: 12px;
    height: 12px;
    background-color: black;
    border: 1px solid black;
    box-shadow: none;
    transform: none;
}

.gallery .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 8px;
    border: 0;
}

.gallery .image-gallery-thumbnails {
    margin-top: 8px;
    padding: 0;
}

.gallery .image-gallery-thumbnail {
    width: 80px;
    height: 80px;
}

.gallery .image-gallery-thumbnail.active,
.gallery .image-gallery-thumbnail:focus,
.gallery .image-gallery-thumbnail:hover {
    border: 2px solid #2871e6;
    border-radius: 4px;
}
.gallery .image-gallery-thumbnail span {
    display: initial;
    border-radius: 4px;
}
.gallery .image-gallery-fullscreen-button {
    padding: 0 20px 0 0;
}

.gallery .image-gallery-icon {
    filter: none;
}

.order-card .ant-collapse {
    border: 1px solid #1a38601a;
    border-radius: 8px;
    background-color: white;
}

.order-card .ant-collapse > .ant-collapse-item {
    border-bottom: none;
}

.order-card .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 15%;
}

.order-tabs .ant-tabs-nav-list .ant-tabs-ink-bar {
    background: #192434 !important;
}

.order-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    color: #192434;
    width: 100%;
    text-align: center;
}

.event-checkbox .ant-checkbox-wrapper {
    align-items: normal;
}
.event-checkbox .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    text-align: center;
    border: 1.4px solid #cbd1da !important;
    border-radius: 2px !important;
}

.event-checkbox .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner::after {
    left: 30%;
}

.event-modal {
    text-align: center !important;
}

.event-modal .ant-space-item {
    width: 100% !important;
}

.event-modal .ant-modal-body {
    padding: 0;
}

.event-modal.info .ant-modal-body {
    background-color: #f4f4f4 !important;
}

.event-modal .ant-modal-content {
    border-radius: 16px;
}

.order .ant-tabs {
    min-height: calc(100vh - 16rem);
}

.order .ant-tabs > div {
    margin-bottom: 20px;
}

.order .ant-tabs-content-holder {
    display: flex;
    align-items: center;
}

.order .ant-tabs-content,
.order .ant-tabs-tabpane {
    min-height: calc(100vh - 20rem);
}

.option .ant-image {
    height: 100%;
    width: 100%;
}
@media (max-width: 400px) {
    .option .ant-image {
        max-height: 267px;
    }
}

.option .ant-image-img {
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.default.ant-modal .ant-modal-header .ant-modal-title {
    font-size: 1.125rem;
    font-weight: 700;
}

.ready .ant-image {
    width: 100%;
}

.region-select .ant-select-selector {
    height: 100% !important;
}

/* mobile */
.mobile p,
.browser p:not(td > p),
.custom-sheet p,
.custom-drawer p,
.custom-modal p {
    font-size: 0.875rem;
}
.mobile p,
.browser p,
.custom-sheet p,
.custom-drawer p,
.custom-modal p {
    margin-bottom: 0 !important;
}

.mobile.space-page,
.mobile .space-page,
.custom-sheet .space-page,
.custom-drawer .space-page {
    overflow-x: hidden;
    padding: 1.5rem 1.5rem 1.5rem;
}

@media (max-width: 300px) {
    .mobile.space-page,
    .mobile .space-page,
    .custom-sheet .space-page,
    .custom-drawer .space-page {
        overflow-x: hidden;
        padding: 1rem 1rem 1rem;
    }
}

.browser.space-page,
.browser .space-page {
    overflow: hidden;
    padding: 4.5rem 1.5em;
    width: 66.66666667%;
    margin: 0 auto;
}

.browser.fixed-page,
.browser .fixed-page {
    margin: auto;
    padding: 2rem;
    width: 80%;
}

.mobile .form .ant-form-item {
    margin-bottom: 0.625rem !important;
}
.mobile .form .ant-form-item.mb-0 {
    margin-bottom: 0 !important;
}

.mobile .custom-divider,
.custom-sheet .custom-divider,
.custom-drawer .custom-divider {
    position: relative;
    border: 5px solid #e8ebef;
    margin: 1.5rem 0;
    width: 100vw;
    left: -1.5rem;
}

.mobile .title-divider {
    width: 60px;
    min-width: 40px !important;
    border: 2px solid #ffcc00;
    margin-top: 12px;
    margin-bottom: 30px !important;
    margin-left: auto;
    margin-right: auto;
}

.browser .title-divider {
    width: 60px;
    min-width: 60px !important;
    margin-top: 20px;
    border: 2px solid #ffcc00;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.reservation.mobile .ant-checkbox-wrapper {
    width: 100%;
}

.custom-sheet .react-modal-sheet-container {
    z-index: 3 !important;
}

.custom-drawer,
.custom-drawer .ant-drawer-content-wrapper {
    height: calc(100dvh - 75px) !important;
    margin-top: 75px;
}

.line-banner .custom-drawer,
.line-banner .custom-drawer .ant-drawer-content-wrapper {
    height: calc(100dvh - 115px) !important;
    margin-top: 115px;
}

.custom-drawer .ant-drawer-body {
    padding: 1.5rem 0 0 0;
}

.custom-select .ant-select-arrow {
    left: 10px;
    width: fit-content;
}
.custom-select .ant-select-selection-item {
    margin: 0 20px;
}
.custom-select .ant-select-clear,
.custom-select .ant-select-clear svg {
    width: 16px;
    height: 16px;
    line-height: 0px;
    opacity: 1;
}

.mobile .option-tabs .ant-tabs-nav-list .ant-tabs-ink-bar {
    background: white;
}

.mobile .option-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    color: #192434;
    width: 100%;
    text-align: center;
}

.mobile .option-tabs .ant-tabs-nav-list {
    width: 100%;
}

.mobile .option-tabs .ant-tabs-tab {
    flex-grow: 1;
    margin: 0.7px !important;
    width: 100%;
    height: 40px;
}

.mobile .option-tabs .ant-tabs-nav-list .ant-tabs-tab {
    background: #e8ebef;
    border-radius: 4px 4px 0 0;
}

.mobile .option-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
    background: #ffffff !important;
    border: 1px solid #e8ebef !important;
    border-radius: 4px 4px 0 0;
}

.mobile .custom-checkbox.agree span:nth-of-type(2) {
    width: 100%;
}

.custom-drawer {
    z-index: 10 !important;
}

.mobile .custom-achor {
    background-color: white;
    margin: 0 !important;
    padding: 0;
}

.mobile .custom-achor .ant-anchor {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    height: 50px;
    overflow-x: auto;
    overflow-y: hidden;
}

.mobile .custom-achor .ant-anchor .ant-anchor-ink {
    display: none;
}

.mobile .custom-achor .ant-anchor .ant-anchor-link {
    padding: 4px 8px 4px 8px;
    text-align: center;
    flex-grow: 1;
    border-bottom: 1px solid #1a38601a;
}

.mobile .custom-achor .ant-anchor .ant-anchor-link a {
    height: 40px;
    line-height: 40px;
}

.mobile .custom-achor .ant-anchor .ant-anchor-link-active {
    border-bottom: 2px solid #192434;
}

.mobile .custom-achor .ant-anchor .ant-anchor-link-active a {
    height: 30px;
    color: #192434;
    font-weight: bold;
}

.mobile .ant-select .ant-select-selector,
.custom-drawer .ant-select .ant-select-selector,
.browser .ant-select .ant-select-selector {
    border-radius: 4px !important;
}

.mobile .btn-basic,
.custom-drawer .btn-basic,
.custom-sheet .btn-basic,
.browser .btn-basic {
    width: 100%;
    border-color: 1px solid rgba(26, 56, 96, 0.1);
    border-radius: 4px;
}

.mobile .btn-dark,
.browser .btn-dark,
.custom-drawer .btn-basic,
.custom-sheet .btn-basic {
    width: 100%;
    border-color: 1px solid rgba(26, 56, 96, 0.1);
    border-radius: 4px !important;
    min-height: 45px !important;
}

.mobile .btn-light,
.browser .btn-light,
.custom-drawer .btn-basic,
.custom-sheet .btn-basic {
    width: 100%;
    border-color: 1px solid rgba(26, 56, 96, 0.1);
    border-radius: 4px !important;
    min-height: 45px;
}

.mobile .btn-light:hover,
.browser .btn-light:hover,
.custom-drawer .btn-basic:hover,
.custom-sheet .btn-basic:hover {
    width: 100%;
    min-height: 45px;
    color: #000000;
    border: 1px solid rgba(26, 56, 96, 0.1) !important;
    border-color: 1px solid rgba(26, 56, 96, 0.1) !important;
    border-radius: 4px !important;
}

.mobile .btn-light-border,
.browser .btn-light-border,
.order-card .btn-light-border,
.custom-drawer .btn-light-border,
.custom-sheet .btn-light-border {
    width: 100%;
    border: 1px solid #001236;
    border-radius: 4px !important;
    min-height: 45px !important;
}

.mobile .btn-danger,
.browser .btn-danger,
.order-card .btn-danger,
.custom-drawer .btn-danger,
.custom-sheet .btn-danger {
    width: 100%;
    color: #e21d12 !important;
    border: 1px solid #e21d12 !important;
    border-radius: 4px;
    min-height: 45px;
}

.mobileFaq img {
    max-width: calc(100vw - 48px);
    padding-top: 8px;
}
.mobileFaqAccordion img {
    max-width: calc(100vw - 88px);
    padding-top: 8px;
}
@media (max-width: 300px) {
    .mobileFaq img {
        max-width: calc(100vw - 32px);
    }
    .mobileFaqAccordion img {
        max-width: calc(100vw - 72px);
    }
}

.loginCard .license .ant-upload-list-picture-card-container {
    width: 100%;
    height: 190px;
    margin: 0 !important;
}

.loading {
    position: relative !important;
    top: 40%;
}

.loading i {
    background-color: #001236;
}

.loading div {
    color: #001236;
}

.loading p {
    padding: 3px 0;
    font-size: 16px;
    font-weight: bold;
}

.license-upload .ant-upload-list,
.license-upload .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
}

/* text color*/
.text-price {
    color: #006af5;
}

.text-note {
    color: #808080;
}

.text-bold {
    font-weight: 700;
}

.backBtn {
    width: 30px;
    height: 30px;
    background: none !important;
    opacity: 0.6;
}

.backBtn svg {
    width: 30px;
    height: 25px;
}

/* Search Input */
.searchForm {
    margin-bottom: 0;
    margin-right: 0 !important;
}
.searchForm .ant-row {
    width: auto;
}
.searchForm .ant-form-item-label,
.searchHead {
    background-color: #1a38601a;
    text-align: center;
    min-height: 42px;
    min-width: 95px;
    line-height: 42px;
    border-right: 1px solid #dfe2e7;
    border-bottom: 1px solid #dfe2e7;
    padding: 0;
}
.searchForm label {
    text-align: center;
    white-space: pre-wrap;
    word-break: keep-all;
    line-height: normal;
}
.searchForm label::after {
    display: none;
}
.searchForm .ant-form-item-control,
.searchBody {
    background-color: white;
    line-height: 30px;
    border-left: 1px solid #dfe2e7;
    border-bottom: 1px solid #dfe2e7;
    padding: 5px 10px;
}
.searchForm input {
    border-radius: 2px;
}
.searchLastRow .ant-form-item-label,
.searchLastRow .ant-form-item-control,
.searchLastRow .searchHead,
.searchLastRow .searchBody {
    border-bottom: none;
}
.ant-col-xs-24 .searchForm .ant-form-item-label {
    flex: 0 0 8.33333333%;
    width: 25%;
    min-width: 25%;
}
.ant-col-xs-24 .searchForm .ant-form-item-control {
    flex: auto;
    background-color: white;
}

/* 띠배너 */
.lineBannerWrapper {
    width: 100vw;
    height: 40px;
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 999;
    background-color: #ffcc00;
    cursor: pointer;
}
.lineBanner {
    padding: 0 1.5rem;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    cursor: pointer;
}
.lineBanner p {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    white-space: nowrap;
}
@media (max-width: 767px) {
    .lineBanner p {
        width: 100%;
        margin: 0;
        text-align: center;
        font-size: 14px;
    }
}
@media (max-width: 355px) {
    .lineBanner {
        padding: 0 1rem;
    }
    .lineBanner p {
        font-size: 13px;
    }
}
@media (max-width: 300px) {
    .lineBanner p {
        font-size: 12px;
    }
}
.lineBannerBtn {
    margin-right: 5px;
}
.lineBannerBtn:hover {
    color: #5b5b5b;
}

.embla__slide.review {
    flex: 0 0 25%;
    padding-left: 20px;
}
.embla__slide.travel-spot {
    flex: 0 0 33.333333333%;
    padding-left: 20px;
}
.embla__slide.rent-video-manual {
    flex: 0 0 20%;
    padding-left: 20px;
}

.simple-slider {
    display: flex;
    gap: 8px;
    width: 100%;
    overflow: auto;
    margin-top: 30px;
    padding: 0 24px 8px 24px;
}

.simple-slider .media-card.best {
    width: 80vw;
}

.simple-slider .review-item {
    position: relative;
    overflow: hidden;
    width: 126px;
    min-width: 126px;
}

.simple-slider .review-item .thumbnail {
    width: 100%;
    height: 126px;
    border-radius: 8px;
    object-fit: cover;
    object-position: 50% 50%;
}

.simple-slider .review-item .review-title {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    color: #192434;
}
.simple-slider .travel-spot-item {
    position: relative;
    overflow: hidden;
    width: 180px;
    min-width: 180px;
    border-radius: 8px;
}

.simple-slider .travel-spot-item .travel-spot-summary {
    padding: 8px;
}

.simple-slider .travel-spot-item .travel-spot-address {
    color: #192434;
    font-size: 13px;
    font-weight: 400;
}

.simple-slider .travel-spot-item .travel-spot-name {
    display: flex;
    justify-content: space-between;
}

.simple-slider .travel-spot-item .travel-spot-name {
    overflow: hidden;
    color: #192434;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 700;
}

.simple-slider .rent-video-manual-item {
    position: relative;
    overflow: clip;
    overflow-clip-margin: 8px;
    width: 126px;
    min-width: 126px;
}

.simple-slider .rent-video-manual-item .thumbnail img {
    scale: 1.34;
}
.browser .media-card.rent-video-manual-item .thumbnail {
    aspect-ratio: 4/3;
    overflow: hidden;
}
.browser .media-card.rent-video-manual-item .thumbnail img {
    scale: 1.4;
}

.rent-video-manual-item .summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.rent-video-manual-item .summary img {
    height: 12px;
}

.simple-slider .review-type {
    display: flex;
    align-items: center;
    margin-top: 3.5px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: rgba(27, 43, 65, 0.69);
}

.main-subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.main-subtitle h4 {
    margin-bottom: 0;
}

.main-subtitle a,
.main-subtitle span {
    color: black;
}

.mobile .main-subtitle {
    margin: 24px 24px 16px;
}

.mobile .main-subtitle h4 {
    font-size: 16px !important;
}

.mobile .main-subtitle a,
.mobile .main-subtitle span {
    font-size: 12px !important;
    font-weight: 700;
}

.main-divider {
    border-top-width: 5px;
    border-top-color: rgba(26, 56, 96, 0.1);
    margin-top: 30px;
    margin-bottom: 6px;
}

.mobile .review-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.mobile .review-list .thumbnail {
    aspect-ratio: 1.16;
}

.mobile .review-list .summary,
.media-card .summary {
    gap: 0;
}

.customer-center .bullet-dash {
    margin: 0;
    padding-left: 10px;
    text-indent: -5px;
    font-size: 16px;
}
.customer-center .mobile .bullet-dash {
    font-size: 14px;
}
.customer-center .bullet-dash::before {
    content: "-";
    position: relative;
    left: -6px;
}
.customer-center .only-reservation {
    display: none;
}

.story p,
.story span:not(.source-text) {
    font-size: 18px;
}
.mobile-story p,
.mobile-story span:not(.source-text) {
    font-size: 16px;
}
.mobile-story br {
    display: none;
}
.mobile-story .my-5 {
    margin-top: 2rem !important;
}
.mobile-story .mb-5 {
    margin-bottom: 2rem !important;
}

.mobile-story .pb-5 {
    padding-bottom: 2rem !important;
}
