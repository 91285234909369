.subTitle {
    color: #192434;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
}

.box p {
    color: #223348;
    font-weight: 700;
    font-size: 16px;
}

.box .input {
    color: #1c304a85;
    font-size: 16px;
    background: #e9ecf0;
    border-radius: 4px;
    border: none;
    height: 40px;
}

.box .button,
.box .button:active,
.box .button:focus {
    width: 100%;
    height: 40px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    border-radius: 2px;
    border: none;
    background-color: #001236;
}
.box .button:hover {
    color: white;
    font-weight: bold;
    font-size: 0.875rem;
    background-color: #ffcc00;
}

.careText {
    font-size: 14px;
    font-weight: 400;
    color: #e21d12;
    margin: 8px 8px 0 0;
}

.noBody {
    width: 100%;
    height: 100%;
    text-align: center;
}

.noBody p {
    height: 100%;
    color: #1c304a85;
    font-size: 18px;
}
