.wrapper {
    width: 100%;
    height: 45px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    :global .react-datepicker__input-container {
        display: flex;
    }
}

.wrapper:hover {
    border: 1.4px solid #aeb3bc;
}

.wrapper:has(.react-datepicker__tab-loop) {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.innerInput {
    width: 100%;
    display: flex;
    align-items: center;
}
.innerInput + button {
    padding-right: 10px;
}
.innerInput + button:after {
    background-color: rgba(0, 0, 0, 0.25) !important;
}

.input {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 2rem;
    font-size: 14px !important;
    color: #000000d9;
}

.icon {
    vertical-align: middle !important;
    padding-left: 10px;
    color: #00000080 !important;
    margin-right: 7px;
}

.wrapper > div {
    height: 100%;
}

.wrapper > div > span {
    display: none;
}

#datepickerWrapper:has(div) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1000;
    padding: 1.5rem;
}

#datepickerWrapper > div > div > div {
    position: static !important;
    transform: none !important;
    padding: 0;
}

#datepickerWrapper > div > div > div > div > div {
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    display: grid !important;
}

#datepickerWrapper > div > div > div:nth-child(2) > div > div > div:first-child {
    display: none;
}

#datepickerWrapper > div > div > div:nth-child(2) > div > div > div:has(.header) {
    width: calc(100vw - 3rem);
    max-width: 400px;
    max-height: 420px;
}

#datepickerWrapper > div > div > div:nth-child(2) > div > div > div:has(.header) > div:first-of-type {
    margin: 0;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
}

.header {
    width: 100%;
    min-height: 120px;
    display: table;
    text-align: center;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    vertical-align: middle;
    font-family: "Noto Sans KR", sans-serif;
}
.header + div {
    padding: 0 10px;
}
.header .selectedDate {
    width: 100%;
    padding: 10px 20px 0 20px;
    font-size: 18px;
    text-align: start;
}

.header .selectedDate span {
    font-size: 12px;
    color: rgb(100, 100, 100);
    display: block;
}
.header .selectedDate p {
    margin-bottom: 0;
    color: black;
    font-weight: bold;
}
.header:has(> span),
.header .month {
    width: 100%;
    height: 100%;
    padding: 0 10%;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: bold;
    font-size: 17px !important;
}

.header .select {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: bold;
    font-size: 14px !important;
    border: 1px solid rgb(100, 100, 100);
    border-radius: 2px;
}
.header select::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #979797;
    border-radius: 4px;
}

#datepickerWrapper > div > div > div:nth-child(2) > div > div > div:last-child > div:last-child:not(.message, .errorMessage),
#datepickerWrapper > div > div > div:nth-child(2) > div > div {
    padding: 0;
    margin: 0;
    height: calc(100% - 180px);
}
#datepickerWrapper > div > div > div:nth-child(2) > div > div > div:last-child > div:last-child > div {
    height: 17%;
    padding: 0 10px;
}

.header + div div {
    width: 12%;
    max-width: 2.4rem;
    height: 100%;
    max-height: 2.4rem;
    line-height: 2.4rem !important;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 17px;
}
.header + div div:first-of-type {
    color: #ff4d4f;
}
.header + div div:last-of-type {
    color: #006af5;
}

#datepickerWrapper > div > div > div:nth-child(2) > div > div > div:has(.message, .errorMessage) {
    width: calc(100vw - 3rem);
    max-width: 400px;
    min-height: 88px;
    margin: 0;
    padding: 10px;
}

.errorMessage {
    border: 1px solid #ffccc7 !important;
    background-color: #fff2f0 !important;
    color: #f90004;
    animation-duration: 3s;
    animation-name: slidein;
    animation-iteration-count: infinite;
}

.reservedToDay:before,
.today:before,
.selectedToday:before,
.peakSeason:before,
.disabledPeakSeason:before {
    content: "";
    display: block;
    position: relative;
    left: 45%;
    top: 34px;
    width: 4px;
    height: 4px;
    border-radius: 20px;
}
.reservedToDay:before,
.today:before {
    background-color: #001236;
}
.selectedToday:before {
    background-color: #ffffff;
}
.peakSeason:before {
    position: absolute;
    background-color: #dc3545;
}
.disabledPeakSeason:before {
    position: absolute;
    background-color: #ffc7cc;
}
.selectedToday,
.selectedDay,
.selectedToday:hover,
.selectedDay:hover {
    background-color: #001236 !important;
    color: white !important;
}
.beforeOpenDay:after {
    display: none !important;
}

.reservedToDay:after,
.disabledDay:after {
    content: "";
    display: block;
    position: relative;
    left: 28%;
    bottom: 19px;
    width: 18px;
    height: 0.5px;
    border-radius: 20px;
    background-color: #999999;
}

.disabledToday {
    color: #ccc !important;
    cursor: default;
    pointer-events: none;
}
@media (max-width: 330px) {
    .header {
        min-height: auto;
    }
    #datepickerWrapper > div > div > div:nth-child(2) > div > div > div:has(.header) {
        height: auto;
        width: auto;
        min-width: 250px;
    }
    #datepickerWrapper > div > div > div:nth-child(2) > div > div > div:has(.message, .errorMessage) {
        width: auto;
        min-width: 250px;
    }
    .header:has(> span),
    .header .month,
    .header .selectedDate p,
    .header + div div {
        font-size: 14px !important;
    }

    .header:has(> span),
    .header .month {
        padding: 0 10px;
    }
    .reservedToDay:after,
    .disabledDay:after {
        content: "";
        display: block;
        position: relative;
        left: 28%;
        bottom: 16px;
        width: 15px;
        height: 0.5px;
        border-radius: 20px;
        background-color: #999999;
    }
}

#datepickerWrapper .holiday {
    color: #ff4d4f;
}

:global .react-datepicker__day--disabled {
    color: #ccc !important;
}
