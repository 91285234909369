.body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    text-align: left;
    margin: 0 !important;
    padding: 0 !important;
    cursor: pointer;
}

.body p {
    padding: 0;
}

.image {
    aspect-ratio: 4/3;
    object-fit: cover;
    object-position: center;
    border-radius: 8px !important;
}

.badge {
    width: fit-content;
    height: fit-content !important;
    background-color: #000000 !important;
    padding: 0 8px 2px 8px !important;
    top: 8px !important;
    left: 8px;
    border-radius: 2px;
}

.topText {
    color: #ffcc00;
    font-size: 16px;
    font-weight: 700;
    margin-top: 6px;
}

.middleText {
    color: #f5f9ff;
    font-size: 12px;
    font-weight: 700;
}

.bottomText {
    color: #f5f9ff;
    font-size: 12px;
    font-weight: 700;
}

.title {
    color: #192434;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 4px;
}

.description {
    color: #1b2b41b0;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.price {
    color: #1c304a85;
    text-decoration: line-through;
    font-size: 12px;
    font-weight: 400;
}

.priceDiscounted {
    color: #005fdb;
    font-size: 16px;
    font-weight: 700;
}

.discountRate {
    color: #ef3d3d;
    font-size: 12px;
    font-weight: 700;
    height: 100%;
}

.tagBody {
    text-align: left;
}

.tagBody .tag {
    color: #1b2b41b0;
    font-size: 10px;
    font-weight: 400;
    border-radius: 4px;
    background-color: #1a38601a;
    border: none;
    margin-right: 5px;
    line-height: 16px;
    padding: 0 4px;
}
