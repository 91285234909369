.popup {
    margin: 20px auto 0 auto;
}
.titleBody {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 18px;
}

.carousel > div:has(button) > div {
    border: none;
    border-radius: 12px !important;
}

.carousel svg {
    width: 24px;
}

.modalText {
    font-weight: 500;
    font-size: 16px;
    word-break: keep-all;
    text-align: center;
    color: black;
}

.secondaryBtn {
    height: 44px !important;
    min-width: 108px;
    border-radius: 4px !important;
    background-color: white !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.primaryBtn {
    height: 44px !important;
    min-width: 108px;
    border: none;
    border-radius: 4px !important;
    background-color: black !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
