.body {
    width: 240px !important;
    height: 224px !important;
    text-align: center;
    padding: 10px;
}

.body p {
    margin-top: 3px;
    margin-bottom: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}

.body .image {
    width: 220px;
    height: 136px;
    border-radius: 8px !important;
}

.title {
    color: #192434;
    font-size: 14px;
    font-weight: 700;
}

.description {
    color: #192434;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
}

.price {
    color: #005fdb;
    font-size: 16px;
    font-weight: 700;
}

.priceDiscounted {
    color: #1c304a85;
    text-decoration: line-through;
    font-size: 12px;
    font-weight: 400;
}

.discountRate {
    color: #ef3d3d;
    font-size: 12px;
    font-weight: 700;
}

.myLocationDot {
    width: 18px;
    height: 18px;
    border: 3px solid white;
    border-radius: 100%;

    background: #447dee;
    box-shadow: 0px 0px 8px #447dee;
}

.myLocationShadow {
    margin-left: -3px;
    margin-top: 0px;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: rgba(68, 181, 238, 0.3);

    animation: 1.75s ease-in-out infinite flickerAnimation;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
