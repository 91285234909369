.myLocationDot {
    width: 18px;
    height: 18px;
    border: 3px solid white;
    border-radius: 100%;

    background: #447dee;
    box-shadow: 0px 0px 8px #447dee;
}

.myLocationShadow {
    margin-left: -3px;
    margin-top: 0px;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: rgba(68, 181, 238, 0.3);

    animation: 1.75s ease-in-out infinite flickerAnimation;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
