.infoWrapper:not(:first-of-type) {
    margin-top: 3rem;
}
.infoImgWrapper p {
    margin-bottom: 0;
    padding-right: 30px;
}

.infoImgWrapper p img {
    padding: 0.5rem;
}

.infoImgWrapper img {
    width: 50%;
}
