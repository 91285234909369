.embla {
    max-width: 48rem;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 100%;
}
.embla__viewport {
    overflow: hidden;
}
.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
    display: flex;
    justify-content: center;
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}
.embla__slide__number {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
}
.embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 1.8rem;
}
.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: "";
}
.embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem var(--text-body);
}
.embla-thumbs {
    --thumbs-slide-spacing: 0.8rem;
    --thumbs-slide-height: 6rem;
    margin-top: var(--thumbs-slide-spacing);
}
.embla-thumbs__viewport {
    overflow: hidden;
}
.embla-thumbs__container {
    display: flex;
    flex-direction: row;
    margin-left: calc(var(--thumbs-slide-spacing) * -1);
}
.embla-thumbs__slide {
    flex: 0 0 22%;
    min-width: 0;
    padding-left: var(--thumbs-slide-spacing);
}
.embla-thumbs__slide__number {
    border-radius: 1.8rem;
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--detail-high-contrast);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--thumbs-slide-height);
    width: 100%;
}
.embla-thumbs__slide--selected .embla-thumbs__slide__number {
    color: var(--text-body);
}

.gallery.embla {
    --slide-height: 100%;
    --slide-spacing: 0;
    --slide-size: 100%;
    max-width: 100%;
    margin: auto;
}
.gallery.embla .embla__slide__image {
    width: 100%;
}
.gallery.embla .embla__controls {
    display: flex;
    justify-content: center;
    margin-top: 28px;
}
.gallery.embla .embla__dots {
    gap: 10px;
    margin-right: 0;
}
.gallery.embla .embla__dot {
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: none;
    height: 12px;
    width: 12px;
}
.gallery.embla .embla__dot--selected {
    background-color: #000;
}
.gallery.embla .embla-thumbs {
    --thumbs-slide-spacing: 8px;
    --thumbs-slide-height: 80px;
}
.gallery.embla .embla-thumbs__slide:first-child {
    margin-left: auto;
}
.gallery.embla .embla-thumbs__slide:last-child {
    margin-right: auto;
}
.gallery.embla .embla-thumbs__slide {
    flex: none;
    width: 88px;
    height: 80px;
}
.gallery.embla .embla-thumbs__slide__button {
    position: relative;
    opacity: 1;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
}
.gallery.embla .embla-thumbs__slide__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.gallery.embla .embla-thumbs__slide--selected button {
    border: 2px solid #2871e6;
    border-radius: 4px;
    transition: all 0.2s;
}
.gallery.embla .embla-thumbs__slide--selected img {
    border-radius: 2px;
}
.brochure_gallery .embla__controls {
    display: none !important;
}
.brochure_gallery .embla-thumbs__slide--selected button {
    border-color: #ffcc00 !important;
}
.brochure_gallery.browser .embla-thumbs__slide {
    width: 110px;
    height: 100px;
}
