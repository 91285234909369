.mobileLayout,
.browserLayout {
    overflow-x: hidden;
    padding: 0;
    background-color: black;
}
.backgroundImage {
    width: 100%;
}
.subTitle {
    font-size: 18px !important;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 20px;
}

.galleryArea {
    padding: 50px 0 10px;
    background-color: black;
}
.galleryWrapper {
    padding: 0 20px 20px;
}
.browserLayout .galleryArea {
    padding: 80px 60px 0px;
}

.carouselArea {
    background-color: white;
    padding-bottom: 30px;
}
.carouselWrapper {
    padding: 0 20px 20px;
}
.carouselWrapper img {
    height: 240px;
}
.carouselWrapper > div > div:first-of-type {
    bottom: 18% !important;
}
.carouselWrapper > div > div:first-of-type button {
    width: 12px !important;
    height: 12px !important;
    background-color: white !important;
}
.carouselWrapper a {
    padding-bottom: 10%;
}
.carouselWrapper a > span:first-of-type {
    opacity: 0.8 !important;
    background-color: transparent !important;
}

.floatingButton {
    width: 50px !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed !important;
    right: 24px;
    bottom: 24px;
    z-index: 999;
    border-radius: 100px !important;
}
.floatingButton svg {
    margin-bottom: 2px;
}

.video1Wrapper {
    padding: 0;
    width: 100vw;
    height: 220vw;
    overflow: hidden;
}
.video2Wrapper {
    width: 100vw;
    height: 50vw;
    padding: 0 20px;
    overflow: hidden;
    position: absolute;
    top: 154vw;
}
.video2Wrapper video {
    width: calc(100vw - 40px) !important;
}
.video {
    position: absolute;
    object-fit: contain;
    object-position: top;
}
@media (min-width: 400px) {
    .video {
        object-fit: fill;
    }
}
